.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(255, 255, 255, 0.23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.form-check-label {
  input {
    width: 20px;
    height: 20px;
  }
}

.image-gallery-slide img {
  height: 600px;
}

.gridded {
  grid-template-columns: 2fr min-content;
}

.gridded-rows {
  grid-template-rows: 1fr 1fr;
}

.imgViewer__img {
  max-height: 60vh;
  &.picker {
    max-width: 50vw !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.d-nav {
  .imgViewer__img {
    max-width: 80vw !important;
  }
}

.imgViewer__content {
  // min-height: 300px;
  border-radius: 0.3rem;

  &.wrapper {
    border-radius: 10px;
    border: none;
  }

  .imgViewer__wrapper {
    img {
      min-width: 85vw;
    }
  }

  .imgMarker {
    min-width: min-content;
    max-width: 80vw;

    .sticky {
      position: sticky;
      top: 0;
      z-index: 9;
      background-color: #fff;
      padding-top: 1rem;
    }

    .modal-header {
      padding: 0 !important;

      border-bottom: none;
      align-items: center;

      .modal-title {
        width: 100%;
      }
      .functions {
        float: left;
      }
      .save-menu {
        float: right;
        margin-left: 10px;
      }
    }

    .imgMarker__container {
      min-width: min-content;
      max-width: 80vw;
    }
  }

  &.picker {
    min-height: min-content;
    max-height: 80vh;
    min-width: 35vw;
    max-width: 35vw;
    border: none;
  }
}

.dropdown-menu.show {
  transform: translate3d(-30px, 40px, 0px) !important;

  button:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
}
