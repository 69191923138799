$hoverBg: rgba(0, 0, 0, 0.05);
.custom-react-select {
  .react-select-selected-options-count {
    background-color: #e9ecef;
    border-radius: 15px;
    padding: 1px 6px;
    // width: 20px;
    // height: 20px;
    text-align: center;
    order: 99;
    float: right;
    margin-left: 10px;
    //margin-top: 3px;
    font-size: 12px;
  }
  .css-9gakcf-option {
    border-left: 3px solid theme-color('primary');
    background-color: transparent !important;
    color: inherit !important;
  }
  .css-9gakcf-option:hover {
    background-color: $hoverBg !important;
    border-left: 3px solid theme-color('primary');
  }
  .css-1n7v3ny-option {
    background-color: transparent !important;
  }
  .css-1n7v3ny-option:hover {
    background-color: $hoverBg !important;
    border-left: 3px solid theme-color('primary');
  }
  .css-1wa3eu0-placeholder {
    margin-left: 0px;
  }
}
.custom-react-select-filter {
  .react-select-checkbox {
    position: relative;
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 10px;
    // width: 20px;
    // height: 20px;
  }
  .react-select-selected-options-count {
    background-color: #e9ecef;
    border-radius: 15px;
    padding: 1px 6px;
    // width: 20px;
    // height: 20px;
    text-align: center;
    order: 99;
    float: right;
    margin-left: 10px;
    //margin-top: 3px;
    font-size: 12px;
  }
  .css-9gakcf-option {
    border-left: 3px solid theme-color('primary');
    background-color: transparent !important;
    color: inherit !important;
  }
  .css-9gakcf-option:hover {
    background-color: $hoverBg !important;
    border-left: 3px solid theme-color('primary');
  }
  .css-1n7v3ny-option {
    background-color: transparent !important;
  }
  .css-1n7v3ny-option:hover {
    background-color: $hoverBg !important;
    border-left: 3px solid theme-color('primary');
  }
  .css-1wa3eu0-placeholder {
    margin-left: 3px;
  }
}
