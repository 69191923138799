.custom-button-dropdown {
  float: right;
  margin-right: 20px;
  .dropdown-menu.show {
    left: auto !important;
    right: 0em !important;
    top: auto !important;
    bottom: 4em !important;
  }

  button {
    max-width: none !important;
    min-width: none !important;
  }
}
