@import '../variables';
// Variables
$columnBg: $stripedColor;
$columnOverlay: rgba(0, 0, 0, 0.03);
$columnBorder: $borderColor;
$sideMenuBtn: rgb(223, 225, 230);
$whitebg: rgb(255, 255, 255);
$border1: rgb(204, 204, 204);
$border2: rgba(0, 0, 0, 0.2);
$striped: $stripedColor;
$active: #285b91;
$active-hover: #285b911e;
$scrollbarBg: rgba(0, 0, 0, 0.3);
$scollbarThumb: rgba(0, 0, 0, 0.5);
$indicatorColor: rgb(255, 153, 31);
$warning: #ffd700;
$success: #45b649;
$created: gray;
$deadlined: #f85032;
$planned: rgb(204, 204, 204);
$loadingOverlay: rgba(255, 255, 255, 0.75);
$statusbarbg: rgba(0, 0, 0, 0.1);
$hover2: #f2f2f2;

// CSS
.roadmap_page {
  overflow: hidden;
  min-height: fit-content;

  .card-body {
    padding: 0px;
  }

  .roadmap__infobar {
    padding-bottom: 2rem;

    .toggle {
      display: flex;
      text-transform: none;

      &:hover {
        background-color: $columnOverlay;
        cursor: pointer;
      }

      .title {
        flex: 1;
        margin-bottom: 0;
      }

      .indicator {
        transition: transform 333ms ease-in-out;
        &.open {
          transition: transform 333ms ease-in-out;
          transform: rotate(-180deg);
        }
      }
    }

    .content {
      padding: 1.25rem;
    }
  }
}

.roadmap_wrapper {
  height: 71vh;
}

.roadmap {
  &__header {
    &__filter {
      text-transform: none !important;
      .cr-search-form.form-inline {
        flex: 1;

        input {
          width: 100%;
        }
      }

      .filter-wrapper {
        margin-bottom: 0 !important;
        display: flex;
        gap: 0.5rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;

        .text-filter {
          width: 200px;
          min-width: 200px;
        }

        .responsible-filter {
          width: 240px;
          // flex: 1;
          // flex-grow: inherit;
        }

        .status-filter {
          width: 180px;
          // flex: 1;
          // flex-grow: inherit;
        }

        .state-filter {
          width: 225px;
          // flex: 1;
          // flex-grow: inherit;
        }

        .settings {
          flex: 0.75;
        }

        .start-date-filter,
        .end-date-filter {
          width: 120px;

          .custom-datepicker {
            margin-left: 0px;
            .date-clear {
              right: 0.2rem;
            }
            input {
              width: 120px !important;
            }
          }

          .react-datepicker__input-container {
            input {
              padding-left: 0.5rem !important;
            }
          }
        }

        .product-category-filter {
          flex: 1;
          //padding-left: 5px;
        }

        .supplier-filter {
          flex: 1;
        }

        .materials-filter {
          flex: 1;
        }

        .roadmap-datepicker {
          z-index: 100;
        }
      }
    }
  }

  &__fab {
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 100;

    &__wrapper {
      display: flex;
      background-color: $whitebg;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 4px;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      .option {
        padding: 5px;
        min-width: 80px;

        &.first {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &.last {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &.active {
          background-color: $active;
          color: #fff;
          cursor: default;
        }

        &:hover:not(.active) {
          cursor: pointer;
          background-color: $active-hover;
        }

        p {
          margin-bottom: 0;
          text-align: center;
        }
      }
    }
  }

  &__inner {
    position: absolute;
    inset: 0px;
    overflow: hidden;

    &__timeline {
      display: flex;
      position: absolute;
      inset: 0px -17px -17px 0px;
      overflow: scroll;
    }

    .scroll {
      &__wrapper-vertical {
        overflow: auto;
        overflow-y: visible;
        position: absolute;
        right: 0;
        top: 57px;
        bottom: 0;
        width: 20px;
        z-index: 99;

        /* width */
        &::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: $scrollbarBg;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $scollbarThumb;
        }
      }

      &__wrapper-horizontal {
        overflow: auto;
        overflow-x: visible;
        position: absolute;
        bottom: 0px;
        z-index: 99;

        /* height */
        &::-webkit-scrollbar {
          height: 10px;
          width: 200px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: $scrollbarBg;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $scollbarThumb;
        }
      }
    }
  }

  &__list {
    flex: 0 0 auto;
    position: sticky;
    left: 0px;
    z-index: 50;
    background-color: #fff;

    &__wrapper {
      position: relative;
      min-height: 78vh;
      border-right: 1px solid $border1;
    }

    &__title {
      //width: 100%;
      width: 330px;
      height: 56px;
      box-sizing: border-box;
      position: sticky;
      top: 0px;
      z-index: 50;
      // padding: 1rem;
      background-color: $columnBg;
      //border-right: 1px solid $border1;
      border-bottom: 1px solid $border1;
      display: flex;
      align-items: center;
      padding-left: 0.5rem;

      .sortmenu {
        font-weight: bold;

        .dropdown-menu {
          transform: translate3d(0, 30px, 0) !important;
        }
      }

      .case-number {
        display: flex;
        align-items: center;
        //justify-content: center;
        width: 7.5rem;
        height: 100%;
        margin-right: 0.5rem;
        //padding-right: 1rem;
        border-right: 1px solid $border1;
        // border-left: 1px solid $border1;
      }

      &.materials {
        width: 100%;
        padding-left: 0.5rem;

        .case-number {
          width: 6.25rem;
        }
      }

      &.extended {
        width: 100%;
        .materials-status {
          border-left: 1px solid $border1;
        }
      }

      .materials-status {
        width: 3rem;
        height: 100%;
      }

      .start-date {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 6rem;
        border-left: 1px solid $border1;
        border-right: 1px solid $border1;
      }

      .end-date {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 6rem;
        border-right: 1px solid $border1;
      }

      .title {
        flex: 1;
        min-width: 249px;
      }

      .icons {
        flex: 1;
        align-items: center;
        height: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding-right: 8px;

        &.materials,
        &.extended {
          flex: none;
        }

        .icon {
          border-left: 1px solid $border1;
          height: 100%;
          padding-left: 8px;
          display: flex;
          align-items: center;

          .responsible {
            height: 28px;
            width: 28px;
          }
        }
      }

      h4 {
        margin-bottom: 0;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      background-color: #fff;
      //border-right: 1px solid $border1;
      padding-left: 0.25rem;
      padding-right: 0.5rem;
      min-height: 45px;

      &.extended {
        .materials-status {
          border-left: 1px solid $border1;
        }
      }

      .materials-status {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        width: 3rem;

        .icon {
          width: 1.5rem;
          height: 1.5rem;

          &.awaiting {
            background-color: $warning;
          }
          &.missing {
            background-color: $created;
          }
          &.has-all {
            background-color: $success;
          }
        }
      }

      .case-number {
        display: flex;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $border1;
        width: 6.25rem;
        margin-left: 0.6rem;
        padding-right: 0.25rem;
        text-align: left;

        .text {
          width: 100%;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.materials {
        height: 6rem;
        padding-left: 0.5rem;

        .title {
          align-self: inherit;
          padding-left: 0.5rem;
          padding-bottom: 11px;
          padding-top: 11px;

          .title-text {
            padding-left: 0;

            font-size: 14pt;

            p {
              width: 240px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .date-info {
            font-size: 10pt;
            height: 1.5rem;
            display: flex;
            align-items: center;
          }
        }

        .list-status-bar {
          .status-bar {
            height: 1.38rem;

            .bar {
              background-color: $statusbarbg;
            }
          }
        }

        .case-number {
          margin-left: -0.9rem;
        }

        .start-date {
          display: flex;
          align-self: stretch;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 6rem;
          border-left: 1px solid $border1;
          border-right: 1px solid $border1;
        }

        .end-date {
          display: flex;
          align-self: stretch;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 6rem;
          border-right: 1px solid $border1;
        }

        .responsible_status {
          align-self: stretch;
          height: inherit;
        }
      }

      .indicator {
        width: 1em;

        &.children {
          display: flex;
          height: 2em;
          align-items: center;
          justify-content: center;

          &:hover {
            cursor: pointer;
          }
        }

        .open {
          transition: transform 333ms ease-in-out;
          transform: rotate(90deg);
        }

        .closed {
          transition: transform 333ms ease-in-out;
          transform: rotate(0deg);
        }
      }

      .responsible_status {
        height: 45px;
        display: flex;
        align-items: center;
        border-left: 1px solid $border1;
        padding-left: 8px;

        &.no-icon {
          width: 28px;
          margin-right: 9px;
        }

        .responsible {
          width: 28px;
          height: 28px;
          cursor: default;

          &.icon {
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: 50px;
          }

          .content {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 70%;
            word-wrap: initial;
            height: 100%;
          }
        }
      }

      &.child {
        padding-left: 5rem;

        .case-number {
          width: 2.5rem;
          padding-right: 0;
          margin-left: 0.5rem;
        }
      }

      .title {
        align-self: stretch;
        flex: 1;
        padding-left: 0.25rem;
        padding-right: 0.5rem;

        .title-text {
          align-items: center;
          display: flex;
          height: 100%;
          padding-left: 0.25rem;

          p {
            width: 240px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.hover {
          &:hover {
            cursor: pointer;
          }
        }
      }

      &.selected{
        .title{
          border: #003341 2px solid;
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
        }
      }

      p {
        margin-bottom: 0;
      }

      &.striped {
        background-color: $striped;
      }
    }
    &__cutoff {
      border-bottom: 1px solid #cccccc;
      position: absolute;
      width: 100vw;
    }
  }

  &__content {
    flex: 0 0 auto;

    &.material {
      min-width: 100%;
    }

    &__wrapper {
      position: relative;
      min-height: 100%;
    }

    &__column {
      display: flex;
      background-color: $columnBg;
      height: 56px;
      position: sticky;
      top: 0px;
      z-index: 41;

      &.material {
        height: 56px;
        border-bottom: 1px solid $border1;
      }

      &.overlay {
        background-color: transparent;
        position: sticky;
        z-index: 10;
        height: 56px;
        margin-top: -56px;
        top: 0px;
      }

      &__time {
        position: absolute;
        display: flex;
        top: -20px;
        bottom: 0px;
        pointer-events: none;

        &__indicator {
          position: absolute;
          background-color: $indicatorColor;
          width: 2px;
          top: 0;
          bottom: 0;
          overflow: hidden;
          height: 72vh;
        }
        .dot {
          position: absolute;
          width: 10px;
          height: 10px;
          background-color: $indicatorColor;
          border-radius: 10px;
        }
      }

      &__date {
        width: 240px;
        text-transform: uppercase;
        border-bottom: 1px solid $border1;

        .inner {
          display: flex;
          flex-direction: column;
          text-align: center;
          height: 100%;
          //border-right: 1px solid $columnBorder;
          box-sizing: none;

          &.active {
            // background-color: $columnOverlay;
          }

          .month_title {
            display: flex;
            width: 240px;
            justify-content: center;
            align-items: center;
            flex: 1;
            border-right: 1px solid $columnBorder;

            .month_subtitle {
              font-size: 0.9rem;
            }
          }

          .quater_title {
            flex: 1;
            width: 240px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9rem;
            border-right: 1px solid $columnBorder;
          }
          .quater_subtitle {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .week_titles {
            display: flex;
            flex: 1;
            width: 240px;
            justify-content: center;
            align-items: center;
            border-right: 1px solid $columnBorder;

            .weekNumber {
              font-size: 0.9rem;
            }
            .monthYear {
              font-size: 0.6rem;
            }
          }

          .months {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 240px;
            .el {
              font-size: 0.7em;
              border-top: 1px solid $columnBorder;
              border-right: 1px solid $columnBorder;

              &:last-child {
                //border-right: none;
              }
            }
          }

          .month_weeks {
            display: grid;
            //width: 57.15px;

            &.cols4 {
              grid-template-columns: repeat(4, 1fr);
            }
            &.cols5 {
              grid-template-columns: repeat(5, 1fr);
            }

            .el {
              font-size: 0.7em;
              border-top: 1px solid $columnBorder;
              border-right: 1px solid $columnBorder;

              &:last-child {
                // border-right: none;
              }
            }
            .first {
              border-left: 1px solid $columnBorder;
            }
          }

          .weeks {
            display: grid;
            width: 240px;
            grid-template-columns: repeat(7, 1fr);

            .el {
              font-size: 0.7em;
              border-top: 1px solid $columnBorder;
              border-right: 1px solid $columnBorder;

              &:last-child {
                //border-right: none;
              }
            }
          }
        }

        &__overlay {
          height: 72vh;
          //width: 240px;
          //position: absolute;
          top: 0;
          //border-right: 1px solid $columnBorder;
          z-index: 30;
          pointer-events: none;
          &.active {
            // background-color: $columnOverlay;
          }

          .months {
            display: grid;
            width: 240px;
            grid-template-columns: repeat(3, 1fr);
            height: 100%;

            .el {
              border-top: 1px solid $columnBorder;
              border-right: 1px solid $columnBorder;
              font-size: 0.7em;
            }
          }

          .month_weeks {
            display: grid;
            height: 100%;

            &.cols4 {
              grid-template-columns: repeat(4, 1fr);
            }
            &.cols5 {
              grid-template-columns: repeat(5, 1fr);
            }

            .el {
              font-size: 0.7em;
              border-top: 1px solid $columnBorder;
              border-right: 1px solid $columnBorder;

              &:last-child {
                //border-right: none;
              }
            }
            .first {
              border-left: 1px solid $columnBorder;
            }
          }

          .weeks {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            height: 100%;
            width: 240px;

            .el {
              font-size: 0.7em;
              border-top: 1px solid $columnBorder;
              border-right: 1px solid $columnBorder;

              &:last-child {
                //border-right: none;
              }
            }
          }
        }
        &__months {
          width: 55.1px;
        }
      }
      &__upper {
        height: 38px;
      }
      &__lower {
        top: 38px;
        height: 18px;
      }
    }
    &__items {
      &__item {
        display: flex;
        position: relative;
        padding: 0.5rem;
        justify-content: center;
        align-items: center;
        height: 45px;

        &__wrapper {
          position: absolute;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 4px;
          min-width: 3px;
          z-index: 30;

          &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px;
            border-radius: 3px;
            min-height: 40px;
            z-index: 30;

            .deadlined {
              // .progress {
              //   border-top-left-radius: 0;
              //   border-bottom-left-radius: 0;
              // }
            }

            .mainDeadlined {
              // .progress {
              //   border-top-right-radius: 0;
              //   border-bottom-right-radius: 0;
              // }
            }
            .progress-bar {
              background-color: gray;
            }

            .success {
              .progress-bar {
                background-color: theme-color('success');
              }
            }
            .info {
              .progress-bar {
                background-color: theme-color('info');
              }
            }
            .graycolor {
              .progress-bar {
                background-color: gray;
              }
            }
            .danger {
              .progress-bar {
                background-color: theme-color('danger');
              }
            }
            .warning {
              .progress-bar {
                background-color: theme-color('warning');
              }
            }

            .realized_line {
              height: 1px;
              align-self: baseline;
              margin-top: 0.1rem;
              z-index: 30;

              .before {
                height: 0.5rem;
                width: 0.5rem;
                margin-top: -0.25rem;
                border-radius: 0.5rem;
                float: left;

                &.early {
                  background-color: $success;
                }

                &.deadlined {
                  background-color: $deadlined;
                }

                &.planned {
                  //background-color: $planned;
                  background-color: $success;
                }
              }
              .after {
                height: 0.5rem;
                width: 0.5rem;
                margin-top: -0.25rem;
                border-radius: 0.5rem;
                float: right;

                &.early {
                  background-color: $success;
                }

                &.deadlined {
                  background-color: $deadlined;
                }

                &.planned {
                  //background-color: $planned;
                  background-color: $success;
                }
              }

              &.early {
                background-color: $success;
              }

              &.deadlined {
                background-color: $deadlined;
              }

              &.planned {
                background-color: $planned;
              }
            }
          }

          &__icons {
            padding-left: 0.25em;
            display: flex;

            .spent {
              padding: 0.2rem 0.5rem 0 0.5rem;
              font-size: 14px;

              &.deadlined {
                color: $deadlined;
              }

              &.early {
                color: $success;
              }
            }

            // .rm-icon {
            // }
          }
        }
        .realized_line {
          z-index: 40;
        }

        &.striped {
          background-color: $striped;
        }
      }

      &__material {
        display: flex;
        flex: 1;
        flex-direction: row;

        .no-products {
          height: 6rem;
        }

        &.striped {
          background-color: $striped;
        }

        .material__card {
          border-radius: 10px;
          margin: 0.5rem;
          padding: 0.5rem 0.75rem;
          height: 5rem;

          &.unknown {
            background-color: $whitebg;
          }
          &.no-supplier {
            background-color: $created;
            color: $whitebg;
          }
          &.not-ordered {
            background-color: $warning;
          }
          &.is-ordered {
            background-color: $success;
            color: $whitebg;
          }

          &.unknown {
            background-color: $whitebg;
            border: 1px solid $border1;
          }

          .content {
            min-width: 11rem;
            //max-width: 21rem;
            height: 4rem;
            max-height: 4rem;
            display: flex;
            flex-direction: column;

            .product {
              flex: 1;
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: bold;
            }
            .unit-amount {
              flex: 1;
              display: flex;
              align-items: center;
              font-size: 14px;
            }
            .supplier {
              flex: 1;
              display: flex;
              align-items: center;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &__info_screen {
    position: absolute;
    height: 100%;
    width: 440px;
    top: 0;
    right: -440px;
    z-index: 110;
    background-color: $whitebg;
    border-left: 1px solid $border2;
    transition: 0.5s;

    .info-screen-loading-overlay {
      .lo-spinner {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 101;
        background-color: $loadingOverlay;
      }
    }

    &.show {
      transition: 0.5s;
      right: 0;
    }

    .roadmap__fab {
      margin-right: 100%;
    }

    .info_content {
      display: flex;
      flex-direction: column;
      //margin: 1em 1em 0.5em 1em;
      padding: 0em 1em 0.5em 1em;
      height: 96%;
      overflow: auto;
      overflow-y: visible;
      overflow-x: hidden;

      .info_header {
        flex: 1;
        display: flex;
        height: 3.5em;
        max-height: 3.5em;
        position: sticky;
        top: 0;
        background-color: $whitebg;
        z-index: 10;
        // padding-bottom: 0.25em;
        padding-left: 1em;
        margin-left: -1em;
        padding-right: 1em;
        margin-right: -1em;
        padding-right: 1em;
        margin-right: -1em;

        // border-bottom: 1px solid $columnBorder;

        .left_content {
          display: flex;
          gap: 0.25em;
          flex-direction: row;
          align-items: center;
          flex: 1;

          .goto-btn {
            flex: 0.75;

            .goto {
              padding: 0 0.2em 0 0.2em;
              max-width: fit-content;
              border: none;
              font-size: 1em;

              &:hover {
                background-color: $hover2 !important;
                color: black;
              }
            }
          }

          .caseInfo {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 216px;

            // &:hover {
            //   text-overflow: unset;
            //   white-space: normal;
            //   word-break: break-all;
            //   background-color: $hover2;
            //   position: absolute;
            //   right: 45px;
            //   top: 12px;
            //   padding: 0.25rem;
            //   border-radius: 5px;
            // }
          }
        }

        .close-icon {
          // flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .info_ks {
        flex: 1;
        height: 90%;
        // padding-top: 0.5rem;
      }

      .info_materials {
        flex: 1;
        height: 90%;
        // padding-top: 0.5rem;
      }

      .info_title {
        p {
          margin-bottom: 0;
        }
      }

      .info_ks_status {
        .status-info {
          padding: 0.5em 0 0 0;
          display: flex;

          .status-icon {
            //width: 3rem;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .icon {
              height: 1.5em;
              width: 1.5em;
              border-radius: 0.25rem;

              &.warning {
                background-color: $warning;
              }

              &.success {
                background-color: $success;
              }

              &.created {
                background-color: $created;
              }
            }
          }

          .status-action {
            flex: 1;

            .btn-group {
              min-width: 7rem;

              .dropdown-toggle {
                background-color: $sideMenuBtn !important;
                border-color: $sideMenuBtn !important;
                color: black;
                text-align: left;
                font-size: 14px;

                &::after {
                  position: absolute;
                  top: 45%;
                  bottom: 0;
                  right: 0.5rem;
                }
              }

              .disabled {
                background-color: #dfe1e6 !important;
                border-color: #dfe1e6 !important;
                color: black;
                text-align: left;
                font-size: 14px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }

              .dropdown-menu {
                left: 2rem !important;
              }
            }
            .form-check-input {
              margin-left: -0.7rem;
              margin-right: 10px;
              margin-top: 6px;
            }
            p {
              margin-bottom: 0;
            }
          }

          .loading {
            flex: 1;
            display: flex;
            align-items: center;
            margin-left: 0.5rem;
          }
        }

        .errors {
          margin-top: 1.5em;
        }
      }

      .info-screen-card {
        margin-top: 1.5em;

        .header {
          padding: 0.5rem 0.75rem;
          text-transform: none;
        }

        .body {
          padding: 0.75rem;
          font-size: 14px;
        }
      }

      .info_ks_content {
        .body {
          .timeInfo {
            padding: 0;
          }
        }
      }

      .timeInfo {
        padding: 0.75rem;

        &.sm {
          .col-sm-4 {
            margin-bottom: 0.25rem;
          }

          p {
            margin-bottom: 0.25rem;
          }

          .spent {
            margin-top: 0.5rem;
          }
        }

        .dates {
          .col {
            margin-bottom: 0;
            text-align: right;
          }
        }

        .spent {
          margin-top: 1.25rem;

          .col {
            text-align: right;
          }
        }
      }

      .info_materials_content {
        .materials {
          margin-top: 1.5em;

          .details-collapse {
            display: flex;
            align-items: center;

            p {
              margin-bottom: 0;
              flex: 1;
            }

            .open {
              transition: transform 333ms ease-in-out;
              transform: rotate(-180deg);
            }
          }

          .body.materials {
            padding: 0.75rem 0 0 0;
            margin-top: 0;

            .status-bar {
              padding-left: 0.75rem;
              padding-right: 0.75rem;
            }

            .material-table {
              margin-bottom: 0;

              thead {
                th:first-of-type {
                  padding-left: 0.75rem;
                }
                th:nth-of-type(2) {
                  padding-right: 0.75rem;
                }
              }
              .product-tr {
                td:first-of-type {
                  margin-left: 0.75rem;
                }
                td:last-of-type {
                  padding-right: 0.75rem;
                }
              }
            }
          }

          .material-table {
            thead,
            tbody {
              th,
              td {
                padding-left: 0;
                padding-right: 0;
              }
            }

            .sortmenu {
              float: right;
              padding-right: 10px;

              .dropdown-menu {
                transform: translate3d(-7.5rem, 21px, 0) !important;
              }
              .text {
                svg {
                  margin-left: 7px;
                }
              }
            }

            .product-tr {
              border-bottom: 1px solid $border1;

              &:hover {
                background-color: $hover2;
              }

              .icon-name {
                display: flex;
                align-items: center;
                max-width: 220px;
                height: 36px;

                .name-text {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .supplier {
                width: 2.5rem;
                .sicon {
                  // display: flex;
                  // justify-content: center;
                  padding-right: 10px;
                  float: right;
                  font-size: 11.2px;
                  .inner {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 26px;
                    height: 26px;
                    border-radius: 15px;
                    background-color: $deadlined;
                    color: $whitebg;
                  }
                }
              }

              .status {
                padding-right: 0;
                width: 110px;

                .status-dropdown {
                  z-index: 99;
                  .text {
                    font-size: 14px !important;
                  }

                  .btn-group {
                    width: 100%;

                    .caret-btn {
                      display: flex;
                      align-items: center;
                      padding: 0.1rem 0.4rem !important;
                      border-radius: 0.25rem;

                      &.missing {
                        background-color: $created;
                        border: 1px solid $created;
                      }
                      &.unordered {
                        background-color: $warning;
                        border: 1px solid $warning;
                        color: black;
                      }
                      &.ordered {
                        background-color: $success;
                        border: 1px solid $success;
                      }

                      .text {
                        flex: 1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .info_materials_add_product {
        position: fixed;
        bottom: 0px;
        right: 17px;
        padding-top: 10px;
        //padding-left: 25px;
        width: 440px;
        z-index: 30;
        min-height: 300px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        .category_menu {
          margin-top: 10px;
        }
        .dropdown {
          margin-bottom: 15px;
          margin-left: 11px;
          width: 95%;
          border: solid 1px $border1;
          border-radius: 0.25rem;
          padding: 5px;
          padding-left: 10px;
          width: 95%;

          span {
            width: 100%;
          }
          .text {
            float: left;
          }
          svg {
            float: right;
          }
          .dropdown-menu {
            max-height: 150px;
            overflow: auto;
            width: 418px;
          }
        }
        .disabled {
          background-color: #e9ecef;
          opacity: 0.7;
        }
        .dropdown-menu.show {
          transform: translate3d(-2px, 28px, 0px) !important;
        }
        .form-control {
          width: 95%;
          margin-left: 11px;
          font-size: unset;
        }
        .error-text {
          padding-left: 10px;
          float: left;
          color: theme-color('danger');
        }

        .actions {
          width: 100%;
          margin-top: 50px;
          padding-right: 10px;

          button {
            font-size: unset;
            float: right;
            //width: 120px;
            padding: 0.25rem 0.5rem;

            margin-bottom: 10px;
          }
          .add {
            margin-right: 0.5rem;
          }
          .delete {
            margin-right: 0.5rem;
          }
        }
        .row {
          margin: 0;
        }
        .spinner-border {
          position: absolute;
          left: 48%;
          top: 40%;
        }
        .add-product-header {
          font-size: 16px;
          font-weight: bold;
          margin-left: 11px;
        }
      }
      .add-product-toggle {
        float: right;
        padding-right: 8px;
      }
    }
  }

  .status-bar {
    display: flex;
    height: 1.75rem;
    align-items: center;
    justify-content: center;

    .bar {
      flex: 1;
      display: flex;
      align-self: stretch;
      background-color: $statusbarbg;
      border-radius: 1rem;
      margin: 0.5rem 0.5rem 0.5rem 0;
      overflow: hidden;

      .ordered {
        background-color: $success;
      }
      .unordered {
        background-color: $warning;
      }
      .missing {
        background-color: $created;
      }

      .unknown {
        background-color: $scrollbarBg;
      }
    }

    .percentage {
      width: 5rem;
      height: 100%;
      display: flex;
      align-items: center;
      .value {
        width: 100%;
        text-align: right;
      }
    }
  }

  .info-screen-card.details {
    .details-collapse {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
        flex: 1;
      }

      .open {
        transition: transform 333ms ease-in-out;
        transform: rotate(-180deg);
      }
    }

    .details-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;

      .detail-item {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        .label,
        .value {
          flex: 1;
        }

        .value {
          margin-left: 1rem;
        }
      }
    }
  }

  &__materials-total {
    display: flex;
    flex-direction: row;

    .infocard {
      width: 140px;
      background-color: $created;
      margin-right: 1rem;
      color: white;
      border-radius: 0.25rem;
      padding-left: 0.5rem;
      padding-bottom: 0.25rem;

      display: flex;
      flex-direction: column;

      .title {
        flex: 1;
        padding-top: 0.25rem;
      }

      .amount {
        font-size: 16pt;
        margin-top: 0.25rem;
        padding-right: 0.5rem;
        text-align: right;
      }

      .unit {
        font-size: 10pt;
        margin-left: 0.25rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .roadmap {
    &__info_screen {
      width: 40%;
      right: -40%;
    }
  }
}

@media screen and (max-width: 2560px) and (min-width: 1920px) {
  .roadmap {
    &__info_screen {
      width: 440px;
      right: -440px;
    }
  }
}

@media screen and (min-width: 2560px) {
  .roadmap_wrapper {
    height: 78vh;
  }
  .roadmap__content__column__time__indicator {
    height: 78vh;
  }
  .roadmap__list__wrapper {
    height: 78vh;
  }
}
