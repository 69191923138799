@import '../variables';
@import '../themes/colors';

#root {
  height: 100vh;
  max-height: 100vh;
}

.cr-app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.authform_logo {
  width: 100%;
}

.forgot-password {
  height: 20px;
  margin-top: 1rem;
  text-align: right;
  display: flex;
  justify-content: space-between;
  p {
    cursor: pointer;
  }
  p:hover {
    text-decoration: underline;
  }
}

.remember-password {
  height: 20px;
  margin-top: 1rem;
  //margin-left: 1rem;
  text-align: right;
  display: flex;
  justify-content: space-between;

  .form-check-input {
    position: relative;
    margin-left: 0;
    margin-right: 10px;
    margin-left: 0;
  }
}

.sub-box {
  height: 20px;
  margin-top: 1rem;
  text-align: right;
  display: flex;
  justify-content: space-between;
  p {
    cursor: pointer;
  }
  p:hover {
    text-decoration: underline;
  }
}

.signature {
  border: 1px solid lightgray;
  width: 100%;
  height: 100%;
}

.button-link {
  background: none;
  border: none;
  margin-top: 5px;
  margin-left: 15px;

  &:hover {
    color: theme-color('btnPrimary');
    text-decoration: underline;
  }

  &_delete {
    background: none;
    border: none;
    margin-left: 15px;
    &:hover {
      color: theme-color('danger');
      text-decoration: underline;
    }
  }
}

.approval-page {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding: 25px;
  overflow: auto;
  max-height: 100vh;

  .mobile {
    margin-bottom: 5em;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .approval-header-wrapper {
    padding: 0.75em;
  }
  .approval-body-wrapper {
    padding: 0.75em;
  }

  .approval-info-container {
    &.mobile {
      margin-bottom: 0;

      .col {
        padding-left: 0;
        padding-right: 0;

        .approval-info-tabel {
          margin-bottom: 0;

          th {
            padding-left: 0;
          }
          td {
            padding-right: 0;
            word-break: break-all;
          }
        }
      }
    }
  }

  .approval-header {
    display: flex;
    align-items: center;
    text-transform: none;

    &.centered {
      justify-content: center;
    }

    h4 {
      flex: 1;
      margin-bottom: 0;
    }
    button {
      // padding-right: 1.25rem;
    }
  }
}

.countryPhoneInput {
  display: flex;

  .countryCodeInput {
    flex: 0.3;
    min-width: 85px;
    margin-right: 5px;
    max-width: 100px;
  }
  .phoneInput {
    flex: 2;
  }
}

.buttonInputContainer {
  display: flex;

  .genericInput {
    flex: 3.5;
    margin-right: 5px;
    max-width: 220px;
  }
  .buttonInput {
    flex: 1;
  }
}

.wysiwyg {
  .toolbarClassName {
  }

  .wrapperClassName {
  }

  .wysiwyg-section-default {
    padding-left: 8px;
    padding-right: 8px;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .rdw-link-modal {
    left: -230px;
    right: 0;
    height: auto;

    .rdw-link-modal-buttonsection {
      display: flex;
      margin: 0;

      button {
        flex: 1;
      }
    }
  }

  .rdw-colorpicker-modal {
    left: -170px;
    right: 0;
    height: 200px;

    &-options {
      overflow-x: hidden !important;
    }
  }

  .public-DraftStyleDefault-block {
    margin: 0;
  }
}

.width-max-content {
  width: max-content;
}

.max-width-max-content {
  max-width: max-content;
}

@media only screen and (max-width: 575px) {
  .approval-page {
    height: 100vh;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .card-body {
    padding: 0.75rem;
  }
}

.no-padding {
  padding: 0;
}

.margin-5px {
  margin: 5px;
}

.margin-10px {
  margin: 10px;
}

.margin-top-5px {
  margin-top: 5px;
}
.margin-top-4px {
  margin-top: 4px;
}

.margin-bot-5px {
  margin-bottom: 5px;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-bot-10px {
  margin-bottom: 10px;
}

.margin-bot-15px {
  margin-bottom: 15px;
}

.margin-left-5px {
  margin-left: 5px;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-right-10px {
  margin-right: 10px;
}

.padding-left-10px {
  margin-left: 10px;
}

.width-100-perc {
  width: 100%;
}

.index-space {
  width: 75px;
}

.max-100px {
  max-width: 100px;
}

.max-150px {
  max-width: 150px;
}

.max-200px {
  max-width: 200px;
}

.max-300px {
  max-width: 300px;
}

.unsupported-container {
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;

  a {
    color: blue !important;
    margin-left: 5px;
    text-decoration: underline;
    color: theme-color('primary');
  }

  .pdf-view {
    width: 80vw;

    a {
      margin-left: 0;
    }
  }
}

.custom-fab {
  padding-top: 30px;
  .rtf {
    margin: 0 25px 25px 0;

    .rtf--mb__c {
      padding: 0 25px 25px 0;

      .rtf--mb {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.bi {
  &-subtotal {
    margin-bottom: 0px;
    margin-top: 6px;
    font-style: italic;
  }
}

.d-nav {
  overflow-y: auto;
  max-height: -webkit-fill-available;
  // border: 1px solid grey;

  img {
    padding: 5px;
    margin: 5px;

    border: 1px solid theme-color('btnCheckbox');

    cursor: pointer;
  }

  img:hover {
    background-color: theme-color('btnCheckbox');
  }
}

.imgViewer__sizer {
  max-width: calc(100vh - 30px);
}

.imgView {
  .modal-header {
    .modal-title {
      width: 100%;
    }
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.template-table {
  margin-bottom: 0;

  thead {
    tr {
      th:first-child {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;

        &:nth-child(2) {
          width: 5%;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(4) {
          width: 5%;
        }
        // &:nth-child(5) {
        //   width: 15%;
        // }
        // &:nth-child(6) {
        //   width: 15%;
        // }
        // &:nth-child(7) {
        //   width: 15%;
        // }
        &:nth-child(8) {
          width: 20%;
        }
        &:nth-last-child() {
          width: max-content;
        }
      }

      &.active {
        @keyframes activeAnimation {
          0% {
            background-color: theme-color('btnCheckbox');
          }
          // 10% {
          //   background-color: theme-color('success');
          // }
          100% {
            background-color: initial;
          }
        }

        animation-name: activeAnimation;
        animation-duration: 2s;
      }
    }
  }
}

.custom-product-create {
  display: flex;
  margin-bottom: 1rem;

  .text {
    flex: 1;
    margin-bottom: 0;
    height: 100%;
    vertical-align: bottom;
  }
}

.edit-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  justify-content: center;
  align-items: center;

  button,
  .fakeBtn {
    flex: 1;
    min-width: 6rem;
    max-width: 6rem;
  }

  &.lg {
    button,
    .fakeBtn {
      min-width: 10rem;
      max-width: 10rem;
    }
  }
}

.user-admin-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .empty {
    flex: 1;
    min-width: 6rem;
    max-width: 6rem;
  }

  .edit-buttons {
    height: 3rem;
    .loginAsButton,
    .empty {
      margin-right: 1rem;
    }

    button.single {
      min-width: 12.5rem;
      max-width: 12.5rem;
    }

    button.font-black {
      color: black !important;
      opacity: 1;
    }
  }
}

.custom-product-table {
  margin-bottom: 0;

  thead {
    tr {
      th:first-child {
        text-align: center;
      }
    }
  }

  .datasheet-modal-button {
    .wrap {
      display: flex;
      justify-content: center;

      .modal-btn {
        min-width: 10rem;
        max-width: 10rem;
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;

        .form-check {
          .form-check-label {
            .col {
              p {
                margin-bottom: 0;
              }
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .product-edit-buttons {
    padding-right: 0.75rem;
  }
}

.feature-table {
  margin-bottom: 0;

  thead {
    tr {
      th:first-child {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;

        .overviewBtn {
          width: 75px;
          height: 35px;
        }

        .customer_name {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }

      &.active {
        @keyframes activeAnimation {
          0% {
            background-color: theme-color('btnCheckbox');
          }
          // 10% {
          //   background-color: theme-color('success');
          // }
          100% {
            background-color: initial;
          }
        }

        animation-name: activeAnimation;
        animation-duration: 2s;
      }
    }
  }
}

.customers-table {
  margin-bottom: 0;

  thead {
    th {
      padding: 0.75rem;
    }
    tr {
      th:first-child {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0.75rem;
        vertical-align: middle;

        .col {
          padding-left: 0px;
          padding-right: 0px;
        }
        .row {
          margin-left: 0px;
          margin-right: 0px;
        }

        .overviewBtn {
          width: 125px;
          height: 35px;
        }

        .customer_name {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }

      &.active {
        @keyframes activeAnimation {
          0% {
            background-color: theme-color('btnCheckbox');
          }
          // 10% {
          //   background-color: theme-color('success');
          // }
          100% {
            background-color: initial;
          }
        }

        animation-name: activeAnimation;
        animation-duration: 2s;
      }
    }
  }
}

.sales-manager-table {
  margin-bottom: 0;

  thead {
    tr {
      th:first-child {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;

        .overviewBtn {
          width: 75px;
          height: 35px;
        }

        .customer_name {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .col {
          margin-bottom: 0 !important;
        }
      }

      &.active {
        @keyframes activeAnimation {
          0% {
            background-color: theme-color('btnCheckbox');
          }
          // 10% {
          //   background-color: theme-color('success');
          // }
          100% {
            background-color: initial;
          }
        }

        animation-name: activeAnimation;
        animation-duration: 2s;
      }
    }
  }
}

.tp-btn {
  // max-width: 135px;
  min-width: 100px;
  max-width: max-content;
}

.tp-item-number {
  display: grid;
  grid-template-rows: min-content min-content min-content;
  text-align: center;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.template-name-input {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;

  h3.h3 {
    margin-bottom: 0 !important;
    height: 33px;
  }

  .template-name-input-inner {
    display: flex;
    align-items: center;
    height: 33px;

    .form-group {
      margin-bottom: 0;
    }
  }
}
.template-textarea-height {
  min-height: 35px;
  max-height: 105px;
}

.element-group-wrap {
  display: flex;
  justify-content: space-between;

  & > * {
    margin-left: 10px;
  }

  & > *:first-child {
    margin-left: 0px;
  }
}

.controls-schema-header {
  cursor: pointer;
  height: 65px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .schema-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;

    .schema-title {
      flex: 1;
      margin-bottom: 0;
    }

    .schema-menu {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;

      .number-of-schemas {
        padding-left: 1em;
        padding-right: 1em;
        margin-right: 1em;

        font-weight: bold;
        .status {
          // display: flex;
          // align-items: center;
          // float: left !important;
          font-size: 16px !important;
        }

        .all_done {
          color: theme-color('success');
          font-size: 16px !important;
        }
      }
    }
  }
}

.quality-insurance {
  // .qi-card-content {
  //   .qi-card {
  //     border-top: none;
  //   }
  // }

  .nav-link:hover:not(.active) {
    background-color: rgba(0, 0, 0, 0.015);
  }
}

.bolded {
  font-weight: bold;
}

.no-border-top {
  border-top: none !important;
}

.version-text {
  margin: 0px 10px;
  border-radius: 4px;
  opacity: 0.86;

  font-size: 12px;
  color: rgba($color: #f8f9fa, $alpha: 0.5);
}

.width-max-content {
  width: max-content !important;
}

.color-text-display {
  width: 100px;
  display: flex;
}

.square {
  float: right;
  width: 20px;
  height: 20px;
  margin-top: 3px;
  margin-bottom: 5px;
  margin-left: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);

  &.primary {
    background: #003341 !important;
    color: white !important;
  }

  &.white {
    filter: none;
  }
}

.green {
  background: #60c363 !important;
  color: white !important;
}
.yellow {
  background: yellow !important;
  color: black !important;
}
.red {
  background: red !important;
  color: white !important;
}

.width-min-100px {
  min-width: 100px;
}

.margin-bot-0 {
  margin-bottom: 0 !important;
}

.caseDocuments_gallery {
  max-height: 25vh;
  overflow-y: auto;

  .border {
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
}

.bi_pie_wrapper {
  display: flex;
  flex-wrap: wrap;
  // overflow-x: auto;
  overflow: auto;
  // height: 100%;
  // max-width: 60vw;
  max-height: 50vh;
  border: 1px solid rgba(0, 0, 0, 0.125);
  justify-content: center;
}

.error-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;

  .error-col {
    align-self: center;
    // margin-bottom: 25%;

    &.simple {
      align-self: flex-start;
      margin-top: 25px;
    }

    .error-page {
      flex: 1;

      .go-back {
        color: theme-color('primary');
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }

      .logo-wrapper {
        flex: 1;

        .logo {
          width: 300px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

.push_message {
  .descriptions {
    display: flex;
    flex: 1;

    .text_warning {
      color: theme-color('orange') !important;
      flex: 1;
      margin-top: 5px;
      text-align: left;
    }
    .text_cap {
      flex: 1;
      margin-top: 5px;
      text-align: right;
    }
  }
}

.flex-justify-content-center {
  display: flex;
  justify-content: center !important;
}

.flex-align-items-center {
  display: flex;
  flex: 1;
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.display-flex {
  display: flex;
}

.badge_user {
  width: 100%;

  &.badge_sm {
    width: 100px;
  }

  &.colorless {
    background-color: transparent !important;
    color: black !important;
    border: 1px solid #ced4da;
  }

  &.borderless {
    border: none !important;
  }
}

.date-labels {
  margin: 0.25rem !important;
  width: 2rem;
  text-align: center;
  align-self: center;
}

.min-width-300 {
  min-width: 300px;
}

.ml-0 {
  margin-left: 0 !important;
}

.user-filter {
  & > :nth-child(1) {
    min-width: 300px;
  }

  .filterCheckbox {
    // position: absolute;
    // left: 0;
    // top: 35px;
    // font-style: italic;
  }
}

.date-input {
  max-width: 150px;

  .react-datepicker__input-container {
    input {
      padding-left: 20px !important;
    }
  }
}

.date-input2 {
  .cr-search-form__input {
    padding-left: 10px !important;
  }
  .react-date-picker__wrapper {
    border: none !important;

    .react-date-picker__calendar-button,
    .react-date-picker__clear-button {
      svg {
        stroke: gray;
      }
    }
  }
}

.expireDate {
  display: flex;

  .date-labels {
    width: auto;
    margin: 0.25rem 0.25rem 0.25rem 0 !important;
  }

  .form-control {
    width: auto;
  }
}

.cr-sidebar__nav-item-icon {
  width: 2rem;
  height: 2rem;
  filter: brightness(0) invert(1);

  &.origColor {
    filter: none;
  }
}

.sa_search-input {
  min-width: 305px;
}

.schema__category {
  display: flex;
  .header {
    flex: 1;
    border-radius: calc(0.25rem - 1px) !important;
    border-bottom: none !important;

    &.btns {
      border-right: none;
    }
  }

  .remove_category {
    display: flex;
    padding: 0;
    margin-left: 0.25rem;

    .icon {
      padding: 0 0.5rem 0 0.5rem;
      align-self: center;
    }
  }
  .add_category {
    display: flex;
    margin-left: 0.25rem;
    padding: 0;
    width: 2rem;
    justify-content: center;

    .icon {
      padding: 0 0.5rem 0 0.5rem;
      align-self: center;

      &.download {
        padding: 0 0.25rem 0 0.25rem;
      }
    }
  }
  .download_group {
    display: flex;
    margin-left: 0.25rem;
    padding: 0;
    width: 2rem;
    justify-content: center;

    .icon {
      fill: #fff;
      padding: 0 0.5rem 0 0.5rem;
      align-self: center;

      &.download {
        padding: 0 0.25rem 0 0.25rem !important;
      }
      svg {
        height: 14px;
        width: 14px;
      }
    }
  }
  .mark_group {
    display: flex;
    margin-left: 1rem;
    margin-top: 0.75rem;
    padding: 0;
    align-self: center;
    min-width: 117px;
  }
}

.schema__category__actions {
  margin-bottom: 30px;
}

.material__category {
  display: flex;
  margin-bottom: 1.5rem;

  .header {
    flex: 1;
    border-radius: calc(0.25rem - 1px) 0 0 calc(0.25rem - 1px) !important;
    border-right: none;

    .category__header {
      border-bottom: none;
      display: flex;
      padding: 0;

      .title {
        padding: 1.25rem 1.25rem;

        margin-bottom: 0;
        align-items: center;
        flex: 1;
        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      .remove_category {
        display: flex;
        border-radius: 0 calc(0.25rem - 1px) calc(0.25rem - 1px) 0;
        padding: 0;

        .icon {
          padding: 0 0.5rem 0 0.5rem;
          align-self: center;
        }
      }
    }

    .category__body {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      border-right: 1px solid rgba(0, 0, 0, 0.125);

      .category__product {
        .product__title {
          display: flex;
          padding-bottom: 0.5rem;

          .title {
            flex: 1;
          }

          .remove_product {
            // font-size: 90%;
            // .icon {
            //   padding: 0 0.5rem 0 0.5rem;
            //   align-self: center;
            // }
          }
        }
      }
    }
  }

  .add_category {
    display: flex;
    margin-left: 0.25rem;
    padding: 0;

    .icon {
      padding: 0 0.5rem 0 0.5rem;
      align-self: center;
    }
  }
  .download_group {
    display: flex;
    margin-left: 0.25rem;
    padding: 0;

    .icon {
      padding: 0 0.5rem 0 0.5rem;
      align-self: center;
    }
  }
}

.schema__selector {
  display: flex;
  align-items: flex-start;

  .categories {
    flex: 1;
  }

  .addButton {
    flex: 0.25;
    padding-left: 1rem;
  }
}

.product__selector {
  display: flex;
  align-items: flex-start;

  .categories {
    flex: 1;
  }

  .addButton {
    flex: 0.25;
    padding-left: 1rem;
  }
}

.category__schemas {
  .category__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .buttons {
      flex: 1;
    }
    .label {
      margin-bottom: 0 !important;
    }
  }

  .control-schema-item {
    display: flex;
    // margin-bottom: 1rem;

    .collapsable-card {
      flex: 1;

      .schema-title {
        margin-bottom: 0;
      }
    }

    .remove_schema {
      display: flex;
      padding: 0;
      max-height: 72px;
      margin-left: 0.25rem;

      .icon {
        padding: 0 0.5rem 0 0.5rem;
        align-self: center;
      }
    }
    .download_schema {
      display: flex;
      margin-left: 0.25rem;
      padding: 0;
      max-height: 72px;
      width: 2rem;
      justify-content: center;

      .icon {
        fill: #fff;
        padding: 0 0.5rem 0 0.5rem;
        align-self: center;

        &.download {
          padding: 0 0.25rem 0 0.25rem !important;
        }
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    .mark_schema {
      display: flex;
      margin-left: 1rem;
      margin-top: -0.75rem;
      padding: 0;
      align-self: center;
      min-width: 117px;
    }
  }

  .admin-menu {
    color: #212529 !important;

    .custom-button-dropdown {
      margin-right: unset;
    }
  }
}

// PREVIEW BOX
.preview_wrapper {
  display: flex;
  justify-content: center;

  &.screen {
    flex-direction: column;
  }

  &.small {
    transform: scale(0.8);
  }
}

.preview_screen_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.preview-clock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  text-align: center;
  align-content: center;
  margin-top: 40%;

  h1 {
    font-weight: 200;
    font-size: 64px;
  }

  &.alt {
    margin-top: 10%;
  }
}

// Previous messages table
.previous-messages {
  .card-body {
    padding: 1.25em;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .pm-table {
      table-layout: auto;

      th:first-of-type,
      td:first-of-type {
        width: 9em;
      }

      th:last-of-type,
      td:last-of-type {
        width: 9em;
      }

      thead th {
        border-top: none !important;
      }

      .error {
        color: theme-color('danger');
      }
    }
  }

  &.recievers {
    max-height: 50vh;
    overflow-y: auto;

    table {
      table-layout: fixed;

      th:first-of-type,
      td:first-of-type {
        width: 10em;
      }
    }
  }

  .deviceToken {
    max-height: 75px;
    overflow: visible auto;
    flex: 1;
    display: flex;
  }
}

// PREVIEW BOX - Notifications
.notifcation-wrapper {
  display: flex;
  align-items: center;

  &.android {
    .notification {
      display: flex;
      flex: 1;

      background-color: #f8f9fa;
      border-radius: 2px;
      margin: 5px;
      flex-direction: column;

      .header {
        display: flex;
        flex: 1;
        height: 30px;
        margin-top: 10px;
        margin-left: 10px;

        .logo {
          width: 20px;
          margin: 5px;
        }

        .app-name {
          flex: 1;
          font-size: 14px;
          color: gray;
          margin-bottom: 0 !important;
          align-self: center;
        }
      }

      .text {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        margin-left: 15px;
        padding-bottom: 10px;

        .title {
          margin-bottom: 0 !important;
          color: black;
        }

        .content {
          margin-bottom: 0 !important;
          color: gray;
        }
      }
    }
  }

  &.ios {
    .notification {
      display: flex;
      flex: 1;

      background-color: #f8f9fa;
      border-radius: 10px;
      margin: 10px;
      flex-direction: column;

      .header {
        display: flex;
        flex: 1;
        height: 30px;

        .logo {
          width: 20px;
          margin: 5px;
        }

        .app-name {
          flex: 1;
          font-size: 12px;
          margin-bottom: 0 !important;
          align-self: center;
        }
      }
      .text {
        background-color: #f0f0f0;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-top: 5px;
        padding-bottom: 10px;
        padding-left: 10px;

        .title {
          margin-bottom: 0 !important;
          color: black;
        }

        .content {
          margin-bottom: 0 !important;
          color: gray;
        }
      }
    }
  }
}

.feature_filter {
  .features {
    width: 350px;
  }

  .groups {
    width: 350px;
  }
}

.customer_overview_filter {
  .filter-select {
    width: 230px;
  }
}

// PREVIEW BOX - IOS
.iphone-x {
  z-index: 0;

  .screen {
    background: #ff268e;
    background: linear-gradient(to bottom, #ff268e 0%, #ff694f 100%) !important;
  }
}

// PREVIEW BOX - ANDROID
.note8 {
  z-index: 0;

  .screen {
    background: #2288fe;
    background: linear-gradient(to bottom, #2288fe 0%, #fdeba9 100%) !important;
  }
}

// Image marking
.imgMarker__saveBtn {
  .primaryBtn {
    min-width: 168px !important;
  }
}

.imgMarker__container {
  &.mobile {
    display: flex;
    justify-content: center;
    align-items: center;

    .react-transform-wrapper {
      width: 100%;
    }
  }
  .imgMarker__img {
    z-index: 0;
    box-shadow: 0px 0px 1px black;

    &.absolute {
      position: absolute !important;
    }
  }
}

.imgViewer__container {
  display: flex;
  flex: 1 1;
  position: relative;
  margin: 10px 0;
  min-height: 60vh;
  max-height: 60vh;
  user-select: none;
  background-color: lightgray;
  align-items: center;

  &.mobile {
    display: flex;
    justify-content: center;
    align-items: center;

    .react-transform-wrapper {
      width: 100%;
    }
  }
  .imgViewer__img {
    z-index: 0;
    box-shadow: 0px 0px 1px black;
    display: flex;
    flex: 1 1;

    &.absolute {
      position: absolute !important;
    }
  }
  .nav-prev {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.25);
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .nav-next {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.25);
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

.filter__status_card {
  width: 80rem;
  height: 4rem;
  border-radius: 0px;
  padding: 5px;
  text-transform: none;
  color: #ffff;
  margin-right: 0px !important;

  &.dark {
    color: #000000;
  }
}

.filter__status_card_text {
  text-align: left;
  font-weight: 700;
  margin-bottom: 0px;
}

.filter__status_card_count {
  text-align: right;
  font-weight: 400;
  line-height: 35px;
  padding-right: 15px;
}

.terms__wrapper {
  .terms__container {
    .term__item {
      .content {
        display: block;
        width: 100%;
        height: 70vh;
        overflow-y: auto;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        background-color: #e9ecef;
        opacity: 1;

        &.sm {
          height: auto;
        }
      }
      p {
        margin-bottom: 0px;
      }
    }
  }

  .term__editor {
    .wysiwyg {
      .toolbarClassName {
        margin-bottom: 0;
        border: 1px solid #ced4da;
        border-top-left-radius: 0.25em;
        border-top-right-radius: 0.25em;
        border-bottom: none;
      }
      .DraftEditor-root {
        border: 1px solid #ced4da;
        border-bottom-left-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
        padding: 5px;
      }
    }
  }
}

.add-employee-btn {
  width: 68%;
  margin-left: -25px;
}

.case-card {
  margin-bottom: 20px;
}

.case-card-header {
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.menu-scrollable {
  max-height: 400px;
  overflow-y: auto;
}

.display-linebreak {
  white-space: pre-line;
}

.greenText {
  color: theme-color('success');
}

.width-20-percent {
  width: 20%;
}

.invisibleBtn {
  margin-left: 0.25rem;
  display: flex;
  padding: 0;
  width: 2rem;
}

.global_message {
  p {
    margin-bottom: 0px;
  }
}

.feature_checkbox {
  &.labelless {
    label {
      .col {
        p {
          display: none;
        }
      }
    }
  }

  &.nocol {
    .form-check-label {
      .col {
        margin-bottom: 0;
      }
    }
  }

  p {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

.feature_overview_card {
  min-height: 150px !important;
  overflow-x: hidden;

  & > div {
    overflow-x: hidden;
    table {
      overflow-x: hidden;
    }
  }
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 0.5rem !important;
}

.customer_feature_tabel_header {
  // height: 140px;
  white-space: nowrap;
  border-top: none !important;

  & > div {
    transform: translate(10px, 20px) rotate(270deg);
    width: 0px;

    & > span {
      padding: 5px 10px;
    }
  }
}

.feature__status_card {
  width: 80rem;
  height: 4rem;
  border-radius: 0px;
  padding: 5px;
  text-transform: none;
  color: #ffff;
  margin-right: 0px !important;

  &.dark {
    color: #000000;
  }
}

.feature__status_card_text {
  text-align: left;
  font-size: 75%;
  font-weight: 700;
  margin-bottom: 0px;
}

.feature__status_card_count {
  text-align: right;
  font-weight: 400;
  font-size: 32px !important;
  line-height: 35px;
  padding-right: 15px;
}

.gallery__figure {
  min-height: 75px;
  height: 75px;

  .gallery__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .gallery__text {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.modal-header-burger {
  .modal-title {
    width: 100%;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
///////////////                     WYSIWYG TOOLBAR CLASSES                    ///////////////
//////////////////////////////////////////////////////////////////////////////////////////////
.wysiwyg-toolbar {
  .rdw-image-modal-btn {
    width: 90px;
  }
  .rdw-link-modal-btn {
    width: fit-content;
  }
  .rdw-colorpicker-modal-style-label {
    width: fit-content;
  }
  .rdw-dropdown-optionwrapper {
    width: 110%;
    padding: 2px;
  }
}
.rdw-image-alignment-options-popup {
  width: 100% !important;
  min-width: 105px;
  align-content: center;
  justify-content: center;

  .rdw-image-alignment-option {
    height: fit-content !important;
    width: fit-content !important;
    min-width: 15px;
  }
}
.public-DraftStyleDefault-ltr {
  text-align: inherit !important;
}
.document-item-icon {
  height: 28px;
  cursor: pointer;
}
.document-tools-icon {
  height: 15px;
}
.create-modal-overview {
  height: 400px;
  overflow: scroll;
}
.create-folder-permissions {
  //padding: 20px;
  margin-bottom: 15px;
  // border: 2px solid grey;
  // border-radius: 2px;
  // background-color: #e2e2e2;
  .form-check-input {
    margin-top: 1.2rem;
  }
  .form-check-label {
    p {
      margin-top: 0.9rem;
    }
  }
}
.move-copy-table {
  max-height: 400px;
  overflow: scroll;

  td {
    cursor: pointer !important;

    div {
      display: flex;
      align-items: center;

      .document-item-icon {
        flex: 1;
        max-width: min-content;
      }
      .document-item-name {
        flex: 1;
        margin-left: 0.75rem;
      }
      .document-item-child-folder-indicator {
      }
    }
  }
}
.crumbs {
  //margin-left: 15px;
  font-size: 16px;
  padding: 12px;

  .can-click:hover {
    font-weight: bold;
  }
}
.document-confirm-body {
  flex: 1 1 auto;
  padding: 0 1.25rem;
  margin-bottom: 70px;
}
.document-confirm-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left !important;
}
.full-width {
  width: 100%;
}
.no-padding {
  padding: 0px !important;
}
.white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}
.border-none {
  border: none;
}
.documents-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  //background-color: #8a8a8a9e;
  left: 0;
}
.no-button {
  background-color: transparent;
}
.no-button:focus {
  outline: none;
}
button:focus {
  outline: none;
}
.btn-outline-danger:focus {
  box-shadow: none;
}
.btn-btnSecondary:focus {
  box-shadow: none;
}
.btn-btnPrimary:focus {
  box-shadow: none;
}
.btn-outline-success:focus {
  box-shadow: none;
}
.capacity-bar {
  width: 400px;
}
.root-data-button {
  width: 150px !important;
}
.sales-manager-customer-table {
  margin-bottom: 0;

  thead {
    tr {
      th {
        width: 20%;
      }
      th:first-child() {
        width: 10%;
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      td {
        width: 20%;
        vertical-align: middle;
      }
      td:first-child() {
        width: 10%;
        text-align: center;
      }

      // &.active {
      //   @keyframes activeAnimation {
      //     0% {
      //       background-color: theme-color('btnCheckbox');
      //     }
      //     // 10% {
      //     //   background-color: theme-color('success');
      //     // }
      //     100% {
      //       background-color: initial;
      //     }
      //   }

      //   animation-name: activeAnimation;
      //   animation-duration: 2s;
      // }
    }
  }
}

.account-warning {
  background-color: yellow;
  color: black;

  .p {
    margin-bottom: 0;
  }
}

.sales-manager-column {
  line-height: 50px;
  p {
    vertical-align: middle;
  }
  button {
    vertical-align: middle;
  }

  .edit-buttons {
    height: 100%;
    flex: 1;
    justify-content: flex-end;
  }
}

.hover-darken {
  &:hover {
    background-color: rgba(200, 200, 200, 0.3);
  }
}

.error-collapse {
  display: flex;
  align-items: center;
  p {
    color: red;
    margin-bottom: 0;
    flex: 1;
  }

  .open {
    transition: transform 333ms ease-in-out;
    transform: rotate(-180deg);
  }
}

.no-upper {
  text-transform: none;
}

.documents-options {
  float: right;
  margin-right: 20px;
  .dropdown-menu.show {
    left: auto !important;
    right: 0 !important;
    top: auto !important;
    bottom: 4em !important;
  }
}

.firewatch_clock {
  .react-datepicker__input-container {
    width: 251px;
    .cr-search-form__input {
      width: 100%;
      padding-right: 0;
    }
  }
  .react-datepicker__time-container {
    float: none;
    width: 100%;
  }
}

.spannedInput {
  width: 5em !important;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.hidable-modal {
  .content {
    display: block;
    width: 100%;
    // height: 70vh;
    overflow-y: auto;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #e9ecef;
    opacity: 1;

    &.sm {
      height: auto;
    }
  }

  .controls {
    display: flex;
    flex-direction: row-reverse;

    .closeBtn {
      flex: 1;
      max-width: 7em;
    }

    .hide__check {
      flex: 1;
      .form-check {
        height: 100%;
        padding-left: 0;
        width: 10em;

        label {
          height: 100%;
          display: flex;
          align-items: center;
        }

        input {
          position: relative;
          flex: 0.5;
        }

        .col {
          flex: 1;
          padding-left: 0;
          p {
            margin-bottom: 0;
            height: 100%;
          }
        }
      }
    }
  }
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.closeAccountContactInfo {
  p {
    margin-bottom: 0.5rem;
  }
}
.logo-error {
  color: theme-color('danger');
}

.btnGroup {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.25em;
  margin-top: 0.25em;
}

.fti {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.declaration {
  max-width: fit-content;
  .maintext {
    width: inherit;
  }
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.fixed-width-control {
  width: 251px;
}

.scrollable-error-list {
  max-height: 200px;
  overflow: auto;
  border: 1px solid lightgray;
  padding: 0.5rem;

  .error-list-title {
    text-transform: uppercase;
    text-decoration: underline;
    color: red;
    font-weight: bold;
  }
}
.filter-modal {
  height: 700px;
  max-height: 700px;
  overflow: auto;
}
.table {
  td {
    border-color: $borderColor;
  }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: $stripedColor;
}

.flex-1 {
  flex: 1;
}

.custom-datepicker {
  max-width: 150px;
  display: flex;
  flex-direction: row;
  margin-left: 5px;

  .react-datepicker-wrapper {
    flex: 1;
  }
  .date-clear {
    position: absolute;
    right: 0.5rem;
    z-index: 99;
    color: hsl(0, 0%, 80%);

    &:hover {
      cursor: pointer;
      color: hsl(0, 0%, 40%);
    }
  }
  input {
    width: 150px !important;

    padding-left: 0.5rem !important;
  }

  &.meta {
    margin-left: unset;
    max-width: unset !important;

    // input {
    //   width: unset !important;
    // }
  }
}
.intervalMonths {
  .form-check {
    float: left;
    width: 8.33333333333%;
    input {
      z-index: 10;
      margin-left: -10px;
    }
    .col {
      padding-left: 5px;
      position: absolute;
      top: -10px;
      left: 0px;
    }
  }
}
.customer-create-tabs {
  border-bottom: 1px solid #ced4da;
  margin-bottom: 10px;
  padding-left: 10px;
  .tab {
    text-align: center;
    cursor: pointer;
    padding: 10px;
    margin-bottom: -1px;
    float: left;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .active {
    //background-color: theme-color('primary');
    //color: white;
    background: rgba(255, 255, 255, 0.23);
    border-bottom: none;
    border: 1px solid #ced4da;
  }
}
.danger {
  color: theme-color('danger');
}
.grayout {
  opacity: 0.6;
  filter: alpha(opacity = 60);
}
.full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.7);
  z-index: 20;
}
.__react_component_tooltip {
  font-size: 12px !important;
  padding: 6px 18px !important;
}
.__react_component_tooltip.show {
  opacity: 1 !important;
}
.react-select {
  & > :nth-child(1) {
    min-width: 250px;
  }
}
.initials-icon {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: 28px;
  height: 28px;
  cursor: default;
  .inner {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-size: 70%;
    word-wrap: initial;
    height: 100%;
  }
}

.case-materials {
  .material-content {
    margin-bottom: 1rem;
    .material-header {
      display: flex;
      margin-bottom: 1rem;

      .spacer {
        flex: 1;
      }
    }

    .product-edit-buttons {
      .edit-buttons {
        button {
          min-width: 7rem;
          max-width: 7rem;
        }

        .fakeBtn {
          flex: 1;
          min-width: 7rem;
          max-width: 7rem;
        }
      }
      // padding-right: 0.75rem;
    }
  }
}

.footer-btns {
  display: flex;
  gap: 5px;

  &.move-images {
    flex: 1;

    .right-btns {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      gap: 0.25rem;
    }
  }
}

.div-spacer {
  height: 1rem;
}

.company-binding {
  margin: 1rem;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .name {
    padding: 0.5rem;
  }
}

.internal-user-data {
  .permission-row {
    .permissions {
      display: flex;

      .perm {
        &.hasAP {
          flex: 1;
        }

        .col {
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.company-bindings {
  .edit-buttons {
    button.single {
      min-width: 12.5rem;
      max-width: 12.5rem;
    }
    button.font-black {
      color: black !important;
      opacity: 1;
    }
  }
}

.company-binding-modal {
  .center-text {
    text-align: center;
    // color: gray;
    // &::after {
    //   content: '...';
    // }
  }
}

.user_avatar {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  float: left;
  &.clickable {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  &.initials {
    flex: 1;
    justify-content: center;
    background-color: #aaaaaa;
    color: #fff;
    font-weight: bold;
  }
}

.profile-inputs {
  .profile-avatar-form {
    input {
      display: none;
    }
    label {
      display: flex;
      align-items: center;
    }

    .user_avatar_spinner {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      margin-right: 10px;
      display: flex;
      align-items: center;
      float: left;
    }

    .user_avatar,
    .user_avatar_spinner {
      &.small {
        height: 25px;
        width: 25px;
      }
    }

    .profile-avatar-button {
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-left: 30px;
      img {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        padding-right: 5px;
      }
    }
  }
  [class^='col-'] {
    margin-bottom: 0px !important;
  }
}

.cropper_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .cropper_box {
    flex: 1;
    min-height: 400px;
    min-width: 400px;
    max-height: 400px;
    max-width: 400px;
    display: flex;
    justify-content: center !important;
    margin: 30px 0px;
    border: 1px solid $borderColor;

    .img-container img {
      width: 100%;
    }

    .cropper-bg {
      background-image: unset !important;
      background-color: white;
    }
    .circular {
      .cropper-crop-box {
        border-radius: 50%;
      }
      .cropper-view-box {
        box-shadow: none !important;
        outline: 0;
        border-radius: 50%;
      }
      .cropper-face {
        background-color: unset !important;
      }
    }
    .cropper-modal {
      background-color: rgb(230, 230, 230);
    }
    .cr-page-spinner {
      position: absolute;
      top: -20px;
    }
  }

  .avatar-form-slider {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 30px;
      text-align: center;
      // margin: 10px;
      font-size: 28px !important;
    }

    input[type='range'] {
      -webkit-appearance: none;
      width: 400px;
      margin: 10px;
      height: 6px;
      background: rgba(179, 179, 179, 0.6);
      border-radius: 15px;
      //border: 2px solid $borderColor;
      //background-image: linear-gradient(#ff4500, #ff4500);
      background-image: linear-gradient(
        theme-color('btnSecondary'),
        theme-color('btnSecondary')
      );
      background-size: 0% 100%;
      background-repeat: no-repeat;
      //cursor: pointer;
    }
    // input {
    //   width: 400px;
    //   margin: 10px;
    //   cursor: pointer;
    //   // border: 2px solid $borderColor;
    //   // border-radius: 15px;
    // }
    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: theme-color('btnSecondary');
      cursor: pointer;
      box-shadow: 0 0 2px 0 #555;
      transition: background 0.3s ease-in-out;
    }

    input[type='range']::-moz-range-thumb {
      appearance: none;
      cursor: pointer;
    }

    input[type='range']::-ms-thumb {
      appearance: none;
    }

    input[type='range']::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      border-radius: 15px;
      background: transparent;
    }

    input[type='range']::-moz-range-track {
      appearance: none;
    }

    input[type='range']::-ms-track {
      appearance: none;
    }
  }
}
.landing-page {
  overflow: hidden !important;
  min-height: unset !important;
  .landing-page-text {
    padding: 10%;
    .row {
      margin: 0;
    }
    .landing-page-text-header {
      margin-bottom: 60px;
      font: normal normal bold 68px Segoe UI;
    }
    .landing-page-text-body {
      margin-bottom: 60px;
    }
    button {
      height: 40px;
      width: 170px;
      margin-bottom: 60px;
      border-radius: 5px;
      margin-right: 10px;
    }
    .landing-page-usp {
      .col-md-3 {
        padding: 0;
      }
      .col-md-9 {
        padding: 0;
      }
      .highlight-number {
        width: 60px;
        font-weight: bold;
        font-size: 18px !important;
        //margin: 25px 0;
        display: table-cell;
        vertical-align: middle;
        height: 100px;
      }
      p {
        display: table-cell;
        vertical-align: middle;
        height: 100px;
        width: 150px;
        //margin: 16px 0;
      }
    }
  }
  .landing-page-image {
    display: flex;
    justify-content: center;
    padding: 10%;
    img {
      height: 680px;
    }
  }
}
.no-more-items {
  background-color: $stripedColor;
  display: block;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  //height: 35px;
  padding: 5px;
  border: 1px solid $borderColor;
  border-radius: 25px;
  width: 300px;
  text-align: center;
}
.customer-overview-checkbox {
  padding-left: 0;
  .form-check {
    padding-left: 0;
    label {
      input {
        margin-left: 0;
      }
    }
  }
}

.case-building-wrap {
  .order-arrows {
    display: flex;
    margin-top: 10px;
    gap: 10px;
  }
}

.empty-content {
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  margin-bottom: 0px !important;
}

.move-images-wrapper {
  display: flex;

  .control-gallery {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    .image-wrapper {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 200px;
      max-height: 200px;
      border-bottom: 2px solid white;

      .image {
        padding-bottom: 0.25rem;
        transition: 0.1s ease;
        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }

        width: 175px;
        height: 175px;
        max-width: 175px;
        max-height: 175px;
        object-fit: contain;
      }

      .name-wrap {
        flex: 1;
        width: 200px;
        height: 50px;
        max-width: 200px;
        max-height: 100px;

        .name {
          margin-bottom: 0;
          text-overflow: ellipsis;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &.active {
        border-bottom: 2px solid rgb(93, 177, 100);
      }
    }
  }

  .qi-card {
    flex: 1;
    border: none;
  }

  .schema__category {
    .header.card {
      border-right: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  .selected-control {
    // background-color: rgba(0, 0, 0, 0.125);
    // padding-top: 1rem;
  }
}

.label-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;

  .custom-tooltip-wrap {
    flex: 1;
    margin-left: 5px;
    .icon {
      max-height: 20px;
    }
  }
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}

.globaldate {
  &.active {
    .fakeDateBtn {
      height: calc(1.5em + 0.75rem + 5px);
      padding: 0.375rem 0.75rem;
      font-weight: 400;
      line-height: 1.5;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      color: #6c7788;
      background-color: #e9ecef;
      display: flex;
      align-items: center;
      width: 150px !important;
      padding-left: 0.5rem !important;
      margin-left: 5px;

      cursor: pointer !important;
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    input {
      cursor: pointer !important;
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.custom-popover-style {
  .popover-header {
    font-size: 18px !important;
  }
}
.react-datepicker__day-name {
  &:first-child {
    visibility: hidden;
    &:after {
      visibility: visible;
      position: relative;
      left: -0.25rem;
      text-align: center;
      content: 'uge';
    }
  }
}
.comment-textarea {
  .form-group {
    label {
      display: none;
    }
    textarea {
      min-height: 500px;
    }
  }
}

.controlplan-title {
  margin-bottom: -20px;
}

.salesmanagers_customer-table {
  overflow: auto;
  max-height: 80vh;
}

.simple-table {
  tbody {
    th {
      width: 50%;
    }
    tr {
      height: 2rem;
    }
  }
}

.preload-loading {
  position: absolute;
  left: 50%;
  top: 50%;
}

.language-picker {
  position: relative;
  margin-top: 10px;
  margin-right: 8px;
  margin-left: auto;
  list-style-type: none;
  cursor: pointer;
  button {
    padding: 0.2rem 0.2rem;
  }
  .language-picker-flags {
    position: absolute;
    top: -132px; // -33px per additional flag
    z-index: 200000;
    width: 32px;
    display: flex;
    flex-direction: column-reverse;
    button {
      opacity: 0.5;
      padding: 0.2rem 0.2rem;
    }
    button:hover {
      opacity: 1;
    }
  }
}

.login-page-language {
  position: relative;
  margin-left: auto;
  // .language-picker{
  //   background-color: #f8f9fa;
  //   border-radius: 5px;
  // }
  // .language-picker-flags{
  //   background-color: #f8f9fa;
  //   border-radius: 5px;
  // }
}

.language-row {
  margin-left: auto;
  list-style-type: none;
  cursor: pointer;
  button {
    padding: 0.2rem 0.2rem;
  }
  .language-row-flags {
    button {
      opacity: 0.5;
      padding: 0.2rem 0.2rem;
      width: 30px;
    }
    button:focus {
      box-shadow: none;
    }
    button:hover {
      opacity: 1;
    }
    .active {
      opacity: 1;
    }
  }
}

.tab-group-flex {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex: 1;
}

.description-wrapper {
  display: flex;
  .description-index {
    width: 50px;
  }
  .description-actions {
    width: 45px;
  }
  .description-editors {
    display: flex;
    flex: 1 1;
    .description-editors-title {
      margin-top: 0.5rem;
      min-height: 25px;
    }
    .DraftEditor-root {
      min-height: 200px;
    }
  }
}
.add-description {
  flex: 1;
  display: flex;
  height: 66px;
  border: 1px dashed darkgray;
  color: darkgray;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.zero-margins {
  margin-right: 0px;
  margin-left: 0px;
}
.toggleable-filter-item {
  visibility: visible;
  opacity: 1;
  height: 42px;
  // transform-origin: top;
  // transform: scale(1, 1);
  transition: all 300ms ease-out;
  overflow: hidden;
}
.toggled {
  visibility: hidden;
  opacity: 0;
  height: 0;
  // transform-origin: top;
  // transform: scale(1, 0);
  transition: all 300ms ease-in;
}
.toggle-filter-group-icon {
  margin-top: 3px;
  cursor: pointer;
  transition-duration: 0.3s;
}
.toggle-filter-group-icon-toggled {
  transform: rotate(180deg);
  transition-duration: 0.3s;
}

.nav-item {
  .nav-item-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    .notice-indicator {
      background-color: red;
      border-radius: 50%;
      height: 0.5rem;
      width: 0.5rem;
    }
  }
}

.lg-md {
  min-width: 550px;
}

.controlplan-no-controlplans {
  height: 300px;
}

.no-controlplans-text {
  padding-top: 125px;
  text-align: center;
}

.supportLog {
  margin-bottom: 0;
  textarea {
    height: 150px;
  }
}
