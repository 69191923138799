$border-color: rgba(0, 0, 0, 0.125);
$activeColor: rgba(0, 0, 0, 0.35);
$hover-color: rgba(0, 0, 0, 0.05);

.sb_control-schemas {
  .sb_header {
    display: flex;
    flex-direction: column;
    flex: 1;

    .createBtn {
      width: fit-content;
      align-self: flex-end;
      margin-bottom: 0.5rem;
    }
  }

  .control-schema-table {
    margin-bottom: 0;

    thead {
      tr {
        th:first-child {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: middle;

          .form-check {
            .form-check-label {
              .col {
                p {
                  margin-bottom: 0;
                }
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.sb_create-control-schema {
  &.no-top {
    border-top: none !important;
  }

  .card-footer {
    .footer-btns {
      justify-content: flex-end;
    }
  }
}

.sb_edit-control-schema {
  border-top: none !important;

  &_header {
    margin-bottom: 1rem;
  }

  .panels {
    padding: 0;
    display: flex;
    flex-direction: row;
  }

  .panel {
    flex: 1;
    padding-bottom: 1rem;

    p,
    h2 {
      margin-bottom: 0 !important;
    }
    .title {
      text-transform: uppercase;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 3rem;
      border-bottom: 1px solid $border-color;
    }

    &.left {
      border-right: 1px solid $border-color;
    }

    &.right {
      .no-relation-selected {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: darkgray;
        padding: 1rem;
        text-align: center;
        cursor: none;
      }
    }

    .content {
      margin-top: 1.75rem;
      margin-right: 1.75rem;

      &.no-margins {
        margin-top: 0;
        margin-right: 0;
      }
    }

    .control-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0.5rem;

      .item-wrap {
        padding: 10px;
        flex: 1;
        display: flex;
        align-items: center;
        height: 40px;
        border: 1px solid $border-color;
        border-right: none;
        cursor: pointer;

        &:hover {
          background-color: $hover-color;
        }

        .name,
        .type {
          flex: 1;
        }

        .warnings {
          flex: 0.5;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }

        // .type {
        //   text-overflow: ellipsis;
        //   overflow: hidden;
        //   white-space: nowrap;
        // }
      }
      
      &.grouped {
        margin-top: 0;
        .item-wrap {
          border-left: none;
          border-right: none;
          border-bottom: none;
          border-top: 1px solid $border-color !important;
        }
      }

      &.active {
        .item-wrap {
          border-color: $activeColor !important;
          border-width: 2px !important;
        }

        .delete-item {
          border-width: 2px;
        }
      }

      &.remove-me {
        .item-wrap {
          background-color: rgba(255, 251, 0, 0.05);
          &:hover {
            background-color: rgba(255, 251, 0, 0.05);
            cursor: auto;
          }
        }
      }

      .empty-index,
      .nav-index {
        width: 3rem;
        height: 50px;
      }

      .nav-index {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .dummy-nav {
          height: 24px;
          size: 24px;
        }
      }

      .delete-item {
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 0;
      }

      .form-check {
        display: flex;
        height: 40px;
        width: 50px;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        border-left: 1px solid $border-color;

        .form-check-label {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          padding-left: 1.5rem;
          padding-bottom: 0.25rem;

          .form-check-input {
            position: relative;
          }
        }
      }

      .add-item {
        flex: 1;
        display: flex;
        height: 40px;
        border: 1px dashed darkgray;
        color: darkgray;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .active.grouped{
      .item-wrap {
        border: 2px solid $activeColor !important;
        border-left: 2px;
        border-right: 2px;
        border-bottom: 2px;
        border-right: 2px;
      }
    }

    .relation-group {
      display: flex;
      flex-direction: row;
      //align-items: center;
      margin-top: 0.5rem;

      .nav-index {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 3rem;
        //height: 50px;
      }

      .ungroup-btn {
        display: flex;
        //height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        border: 1px solid $border-color;
        width: 50px;

        .form-check {
          display: flex;
          height: 40px;
          width: 50px;
          justify-content: center;
          align-items: center;
          padding-left: 0;

          .form-check-label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            padding-left: 1.5rem;
            padding-bottom: 0.25rem;

            .form-check-input {
              position: relative;
            }
          }
        }
      }

      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        border: 1px solid $border-color;
        border-right: none;

        &.active {
          .item-wrap {
            border-color: $activeColor;
            border-width: 2px;
          }

          .delete-item {
            border-width: 2px;
          }
        }

        .header {
          padding: 10px;
          flex: 1;
          display: flex;
          align-items: center;
          height: 40px;
          cursor: pointer;
          width: 100%;

          &:hover {
            background-color: $hover-color;
          }

          .name,
          .type {
            flex: 1;
          }

          .name {
            font-weight: bold;
          }

          .warnings {
            flex: 0.5;
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
        }

        .items {
          width: 100%;
          flex: 1;
        }
      }

      .inner.active {
        border-color: $activeColor !important;
        border-width: 2px !important;
        border-right: none;
      }

      .ungroup-btn.active {
        border-color: $activeColor;
        border-width: 2px !important;
      }
    }

    .grouping-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 0.5rem;
      margin-top: 0.5rem;
      margin-left: 3rem;

      .btn-block {
        margin-top: 0;
      }
    }
  }

  .relation-wrapper {
    .settings {
      padding: 1.75rem;
      border-bottom: 1px solid $border-color;

      p {
        margin-bottom: 1rem !important;
      }
    }

    .criteria-wrapper {
      padding: 1.75rem;
    }

    .criteria {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 0.5rem;
      gap: 1rem;

      .criteria-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .selection {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;

          .custom-react-select {
            .css-yk16xz-control {
              border-radius: 0 !important;
              border-right: none !important;
            }
          }

          .delete-item {
            display: flex;
            // height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 0;
          }

          .custom-react-select {
            flex: 1;
          }

          .remove-me-indicator {
            padding-left: 1rem;
          }
        }

        .validation-wrapper {
          padding-top: 1rem;
          width: 100%;

          .unknown {
            padding: 0.5rem;
            width: 100%;
            // border: 1px dashed darkgray;
            text-align: center;
            color: darkgray;
          }

          .custom-react-select {
            margin-bottom: 1rem;
          }

          .row {
            padding-left: 0;
          }
        }
      }

      .add-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        border: 1px dashed darkgray;
        color: darkgray;
        cursor: pointer;
      }

      .behavior {
        display: flex;
        flex-direction: row;
        width: 95%;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        .line {
          border: 1px dashed $border-color;
          flex: 1;
          height: 1px;
        }

        .andor-btns {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;

          .btn {
            border: 1px solid $border-color;
            border-radius: 5px;
            flex: 1;
            height: 1.5rem;
            font-size: small;
            display: flex;
            justify-content: center;
            align-items: center;

            &.active {
              background-color: theme-color('secondary');
              color: white;
            }

            &:hover {
              cursor: pointer;
              background-color: theme-color('secondary');
              color: white;
            }
          }
        }
      }
    }
  }

  .card-footer {
    .footer-btns {
      justify-content: flex-end;
    }
  }
}

.category__schemas {
  .schema-inputs {
    .custom-datepicker {
      align-items: center;
      margin-left: 0;
      justify-content: flex-start;
      .react-datepicker-wrapper {
        flex: 0;
      }
      .date-clear {
        position: absolute;
        margin-left: -1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
}

.control-type-filter {
  & > :nth-child(1) {
    min-width: 300px;
  }
  & > :nth-child(2) {
    min-width: 300px;
  }
}

.signing_schemas {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 25px;
  overflow: auto;
  max-height: 100vh;

  &.mobile {
    padding: 10px;
    padding-bottom: 10rem;

    .control-schema-item {
      width: 100%;
    }
  }

  .signing_description {
    margin: 0;
    margin-top: 0.75rem;
  }

  .control-schema-item {
    margin-top: 1rem;
  }
}

.group-indexing-wrapper {
  .header {
    margin: 1rem 1.5rem 0 1.75rem;
  }

  .control-item {
    padding-left: 0.5rem;
    margin-right: 1.75rem;

    .item-wrap {
      border-right: 1px solid $border-color !important;
    }
  }
}
