@import '../../variables';

$thumb-size: 16pt;

.image-marking-wrap {
  flex: 1;
  height: 100%;

  .split-view {
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 100%;

    .left,
    .right {
      flex: 1;
      display: flex;
      width: 50%;
    }

    .left {
      padding: 1rem 0.5rem 1rem 1rem;

      .no-image-active {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .right {
      padding: 1rem 1rem 1rem 0.5rem;
    }

    .split {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
    }
  }

  .active-image {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    .img {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: lightgray;
      border-radius: 5px;
      max-height: 60vh;
      min-height: 60vh;

      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */

      .display-id {
        position: absolute;
        top: 2%;
        left: 7%;
        font-size: $thumb-size;
        color: white;
        // -webkit-text-stroke: 1px;
        // -webkit-text-stroke-color: black;
        text-shadow: 0px 0px 2px black;
        z-index: 2;
      }

      .preview {
        max-width: 100%;
        max-height: 60vh;
        z-index: 1;
      }
      .nav-prev {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        cursor: pointer;
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.25);

        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }
      }

      .nav-next {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.25);

        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }

        cursor: pointer;
      }
    }
  }

  .konva-view {
    width: 100%;
    height: 100%;
  }

  .konva-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .mini-gallery {
      display: flex;
      flex-direction: row;
      gap: 5px;

      &.editor,
      &.viewer {
        flex-direction: column;
        overflow-x: auto;

        .p {
          margin-bottom: 0;
        }

        .items {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          width: fit-content;
        }
      }

      &.viewer {
        margin-top: 1rem;
      }

      .thumb {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        width: 4.5rem;
        height: 4.5rem;
        overflow: hidden;
        position: relative;
        background-color: lightgray;

        &.did {
          .thumb-img {
            opacity: 0.5;
          }
        }

        &.active:hover {
          cursor: pointer;

          .thumb-img {
            opacity: 0.25;
            transition: opacity ease 0.5s;
          }
        }

        .thumb-img {
          max-width: 8rem;
          max-height: 8rem;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .display-id {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: $thumb-size;
          color: white;
          background-color: rgba(0, 0, 0, 0.3);
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .icon {
          position: absolute;
          right: 0%;
          top: 5%;
          transform: translate(-50%, -5%);
          fill: #003341;
          // fill: white;
          width: 1rem;
          height: 1rem;
        }
      }
    }
    .konva-actions {
      .p {
        margin-bottom: 0.25rem;
      }

      .actions {
        display: flex;
        gap: 5px;
      }
    }
    .imgMarker__container {
      // flex: 1;
      max-height: 100% !important;
    }
  }
}

.image-marking-modal {
  max-width: 90vw;
  width: 90vw;

  &.mobile {
    max-width: 100%;
    width: 100%;
    margin: 0;

    .modal-content {
      max-width: 100%;
      width: 100%;
      margin: 0.25rem;
    }
  }

  .modal-content {
    max-width: 90vw;
    width: 90vw;
  }

  .modal-header {
    .modal-title {
      width: 100%;
    }

    .header-btns,
    .header-view {
      display: flex;
      gap: 5px;
      font-size: 11pt;
    }

    .header-view {
      flex: 1;
      width: 100%;
      .h1 {
        flex: 1;
      }
    }

    .h1 {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .modal-body {
    padding: 0;
  }
}
