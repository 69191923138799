// imports
@import 'notification-menu';

// Header variables
$headerHeight: 56px;
$headerBg: rgb(0, 51, 65);
$logoFill: #fff;
$logoHoverBg: #fff;
$logoHoverFill: rgb(0, 51, 65);
$logoTextColor: #fff;
$profileTextColor: #fff;
$menuItemHover: rgba(255, 255, 255, 0.1);
$menuItemIconFill: #fff;
$messageMaxWidht: 475px;

.custom-header {
  display: flex;
  align-items: center;
  height: $headerHeight;
  max-height: $headerHeight;
  background-color: $headerBg;

  .logo-content {
    display: flex;
    align-items: center;
    width: 225px;

    .text {
      color: $logoTextColor;
      margin-bottom: 0;
      margin-left: 1rem;
      margin-right: 1rem;
      text-transform: uppercase;
    }

    &:hover {
      // background-color: $logoHoverBg;
      .logo-wrapper {
        cursor: pointer;
        // .logo {
        //   fill: $headerBg;
        // }
      }
    }

    .logo-wrapper {
      height: $headerHeight;
      max-height: $headerHeight;
      margin-left: -6px;

      a {
        height: inherit;
      }

      .img-logo {
        margin: 0.5rem;
        // padding-left: 0.5rem;
        height: $headerHeight - 16;
        max-height: $headerHeight - 16;
      }

      .logo {
        margin: 0.5rem;
        height: $headerHeight - 16;
        max-height: $headerHeight - 16;
        fill: $logoFill;
      }
    }
  }

  .menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: $headerHeight;
    max-height: $headerHeight;

    .menu-item {
      height: $headerHeight;
      max-height: $headerHeight;

      .icon-wrap {
        padding-left: 1rem;
        padding-right: 1rem;
        height: 100%;
        display: flex;
        align-items: center;

        .icon {
          fill: $menuItemIconFill;
          height: 28px;
        }

        &:hover {
          cursor: pointer;
          background-color: $menuItemHover;
        }

        &.active {
          background-color: $menuItemHover;
        }
      }
    }
  }

  .profile {
    padding-left: 0.75rem;
    height: $headerHeight;
    max-height: $headerHeight;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    &.active,
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .info {
      color: $profileTextColor;
      .name,
      .company {
        margin-bottom: 0;
        text-align: right;
        padding-right: 1rem;
      }

      .company {
        font-size: 12px !important;
        color: #ddd;
      }
    }

    .user_avatar.header {
      display: block;
      height: $headerHeight - 24;
      width: $headerHeight - 24;
      float: none;
    }

    .dropdown-menu.show {
      transform: translate3d(-180px, 30px, 0) !important;

      a {
        text-decoration: none !important;
      }

      .btn-wrap {
        display: flex;
        // gap: 1rem;
        align-items: center;
        flex-direction: row;
        text-align: left;

        svg {
          height: 24px;
          width: 24px;
          fill: $headerBg;
        }

        .text {
          flex: 1;
        }

        .icon {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex: 0.5;
          width: 100%;
        }
      }
    }
  }

  .global_message {
    flex: 1;
    height: $headerHeight;
    max-height: $headerHeight;
    max-width: $messageMaxWidht;
    overflow: hidden;

    .content {
      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .white {
      color: white !important;
      filter: none !important;

      a {
        color: white !important;
      }
    }

    .black {
      color: black !important;
      filter: none !important;

      a {
        color: black !important;
      }
    }
  }
}
