// .sb_schema-categories {
//   border-top: none !important;
// }
// .sb-sc-card-create {
//   min-height: 84vh;
// }
// .sb-sc-card-update {
//   min-height: 70vh;
// }
.bottom-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.schema-category-schema-list {
  tr {
    th:first-child {
      text-align: center;
    }
  }
}
.card-footer {
  .footer-btns {
    justify-content: flex-end;
  }
}
