@import '../../variables';
$border-color: rgba(0, 0, 0, 0.125);
$action-fill: rgb(0, 51, 65);
$action-fill-hover: rgba(0, 51, 65, 0.7);
$side-width: 50px;

.control-plan-title-count {
  display: contents;
  color: theme-color('btnCheckbox');
  font-size: 1.7rem;
}

.control-plan-wrapper {
  .dummy {
    width: $side-width;
    // background-color: lightblue;
    min-height: 10px;

    &.left {
      width: 50px;
    }
    &.right {
      padding-left: 1rem;
    }
  }

  .add-category-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    .dummy.right {
      width: 12rem;
    }

    .add-category-item {
      flex: 1;
      cursor: pointer;

      .centered-watermark-text {
        border-color: rgb(169, 169, 169);
        .centered-watermark-text__text {
          font-size: 14px;
          color: rgb(169, 169, 169);
        }

        &:hover {
          border-color: rgb(138, 138, 138);

          .centered-watermark-text__text {
            color: rgb(138, 138, 138);
          }
        }
      }
    }
  }

  .control-plan-headers {
    border-top: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;

    .p {
      font-weight: bold;
      margin-top: 1rem;

      &.id {
        // background-color: lightblue;
        text-align: right;
        width: $side-width;
        padding-right: 1rem;
      }
      &.schema {
        // background-color: lightgreen;
        flex: 1;
      }
      &.actions {
        // background-color: lightblue;
        width: $side-width;
        padding-left: 1rem;
        text-align: center;
        height: 10px;
      }
    }
  }

  .control-plan-category-wrapper {
    margin-bottom: 3rem;

    .control-plan-category-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .category-header-title {
        display: flex;
        align-items: center;
        justify-content: left;
        flex: 1;
        // background-color: lightgreen;

        .title {
          margin-bottom: 0;
        }

        .action-btn {
          display: flex;
          justify-self: center;
          align-items: center;
          margin-left: 1rem;
          svg {
            flex: 1;
            fill: $action-fill;

            &:hover {
              fill: $action-fill-hover;
              cursor: pointer;
            }
          }
        }
      }
    }

    .add-control-plan-item {
      display: flex;
      align-items: center;
      justify-content: left;

      .dummy.right {
        width: 13.25rem;
      }

      .centered-watermark-text {
        flex: 1;
        border-color: rgb(169, 169, 169);
        cursor: pointer;

        &:hover {
          border-color: rgb(138, 138, 138);

          .centered-watermark-text__text {
            color: rgb(138, 138, 138);
          }
        }

        .centered-watermark-text__text {
          color: rgb(169, 169, 169);
          font-size: 14px;
        }
      }
    }

    .category-info {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 1rem;

      .info-item {
        .title {
          margin-bottom: 0;
        }
      }
    }
  }
}

.controlplan-card {
  .mb0 {
    margin-bottom: 0 !important;
  }

  .mt1 {
    margin-top: 1rem !important;
  }

  .form-group {
    margin-bottom: 0;
  }
}
