.fileDropContent {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;

  button {
    height: 50px;
  }

  &.moving-images {
    .fileDropzone {
      pointer-events: none;
    }
  }
}

.fileDropzone {
  height: 65px;
  border: 1px dashed darkgray;
  color: darkgray;
  display: grid;
  margin-bottom: 15px;

  &:hover {
    border-color: gray;
    color: gray;
    cursor: pointer;
  }

  & .fileInput {
    display: none;
  }

  & > div {
    align-self: center;
    justify-self: center;
  }
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-auto-rows: 90px;
  gap: 10px;
  width: 100%;

  &__figure {
    position: relative;
    border: 1px solid grey;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:hover::after {
      background-color: rgba(255, 255, 255, 0.4);
      cursor: pointer;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.imgViewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1020;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: hidden;

  &__header {
    padding: 0;
    padding-bottom: 5px;
    margin-top: 5px;
  }

  &__content {
    max-height: calc(100vh - 20px);
    padding: 1rem;
    background-color: #fff;
    border: 1px solid grey;
    display: grid;
    gap: 10px;
    overflow-y: auto;

    &.marking {
      padding: 0 1rem 1rem 1rem;
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.imgMarker {
  justify-self: center;

  &__wrapper {
    display: grid;
  }

  &__container {
    position: relative;
    max-width: 80vh;
    margin: 10px 0;
  }

  &__img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__btnGroup {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
  }

  &__markeringBtnGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    margin-bottom: 10px;
  }

  &__btn {
    &--up {
      grid-row: 1;
      grid-column: span 2;
    }
    &--left {
      grid-row: 2;
    }
    &--right {
      grid-row: 2;
    }
    &--down {
      grid-row: 3;
      grid-column: span 2;
    }
    &--option {
      input[type='radio'],
      input[type='checkbox'] {
        margin-left: auto;
      }
      label {
        margin-bottom: 0 !important;
        padding-left: 1.25rem;
        cursor: pointer;
      }
    }
  }

  &__saveBtn {
    // margin-top: 10px;
  }
}
