.schemabuilder-overview-header {
  tr {
    th:first-child {
      text-align: center;
    }
    th {
      border-top: none;
    }
  }
}

.schemabuilder-overview-body {
  .tp-item-number {
    width: 24px;
  }
}

.delete-modal-list {
  .custom-table-wrapper {
    height: 60vh !important;
  }
}

.dataview-modal-list {
  .custom-table-wrapper {
    height: 60vh !important;
  }
}

.schemabuilder-filters {
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  .custom-react-select {
    flex: 1;
    max-width: 400px;
  }
}

.schemabuilder-downloadBtn {
  border: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
