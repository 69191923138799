// Notification menu variables
$wrapper-bg: #fff;
$wrapper-border: rgba(0, 0, 0, 0.1);
$scrollbarBg: rgba(0, 0, 0, 0.1);
$scollbarThumb: rgba(0, 0, 0, 0.2);
$notis-bg: #fff;
$notis-hover: rgba(0, 0, 0, 0.05);
$avatar-size: 40px;
$subtext-size: 12px;
$subtext-color: rgb(147, 147, 147);
$subicon-bg: rgb(0, 51, 65);
$subicon-fill: #fff;
$indicator-color: rgb(0, 51, 65);
$action-fill: rgb(0, 51, 65);
$action-fill-hover: rgba(0, 51, 65, 0.7);
$dummy-height: $avatar-size + 32px;
$gradient-start-end: #d6d6d6;
$gradient-middle: #f3f3f3;
$empty-text-color: #b6b6b6;

.notifications-menu-wrapper-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1018;
  display: none;
  &.show {
    display: block;
  }

  .notifications-menu-wrapper {
    position: absolute;
    z-index: 100;
    margin: 6.5vh;
    background-color: $wrapper-bg;
    top: 0;
    right: 0;
    width: 500px;
    border-radius: 5px;
    border: 1px solid $wrapper-border;
    z-index: 1019;

    .notifications-content {
      box-shadow: 0px 0px 10px 0px #888888;
      .header {
        padding: 1rem;

        .title {
          font-weight: bold;
        }

        .filters {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .btn {
          }
        }
      }

      .notifications {
        max-height: 70vh;
        overflow: auto;

        // Scrollbar
        /* height */
        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: $scrollbarBg;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $scollbarThumb;
        }

        .notification {
          display: flex;
          justify-content: center;
          padding: 0.5rem 0 0.5rem 1rem;

          &.dummy {
            height: $dummy-height;

            .icon {
              height: $avatar-size;
              width: $avatar-size;
              border-radius: 50%;

              background: linear-gradient(
                270deg,
                $gradient-start-end,
                $gradient-middle,
                $gradient-start-end
              );
              background-size: 400% 400%;

              animation: dummy 3s ease infinite;
            }

            .content {
              display: flex;
              flex-direction: column;

              .dummy {
                flex: 1;
                border-radius: 5px;
                margin: 0 10px 5px 10px;

                &.small {
                  margin: 0 10px 5px 10px;
                  max-height: 10px;
                  max-width: 25%;
                }

                background: linear-gradient(
                  270deg,
                  $gradient-start-end,
                  $gradient-middle,
                  $gradient-start-end
                );
                background-size: 400% 400%;

                animation: dummy 5s ease infinite;
              }
            }

            @keyframes dummy {
              0% {
                background-position: 0% 50%;
              }
              50% {
                background-position: 100% 50%;
              }
              100% {
                background-position: 0% 50%;
              }
            }
          }

          &.empty {
            height: $dummy-height * 2;
            align-items: center;
            color: $empty-text-color;
          }

          &.unhandled {
            &:hover {
              cursor: pointer;
              background-color: $notis-hover;
            }
          }

          .icon {
            display: flex;
            justify-content: center;
            padding-right: 1rem;

            .main-icon {
              width: $avatar-size;
              height: $avatar-size;
              margin-right: 0;
            }

            .avatar {
              width: $avatar-size;
              height: $avatar-size;
              margin-right: 0;
            }

            .sub-icon-wrapper {
              // position: fixed;
              height: 20px;
              width: 20px;
              margin-top: 1.7rem;
              margin-left: -1.5rem;

              .sub-icon {
                padding: 4px;
                border-radius: 50%;
                background-color: $subicon-bg;
                fill: $subicon-fill;
              }
            }
          }

          .content {
            flex: 1;

            .top {
              display: flex;
              gap: 5px;

              .left {
                flex: 1;
                width: 355px;

                .text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 3; /* number of lines to show */
                  line-clamp: 3;
                  -webkit-box-orient: vertical;
                }
              }

              .right {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .actions {
                  flex: 0.4;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  gap: 5px;
                  margin-right: 0.5rem;

                  .action {
                    fill: $action-fill;
                    width: 24px;

                    &:hover {
                      cursor: pointer;
                      fill: $action-fill-hover;
                    }
                  }
                  svg{
                    width: 20px;
                    height: 20px
                  }
                }

                .indicator {
                  flex: 0.4;
                  height: 100%;
                  display: flex;
                  // justify-content: flex-end;
                  align-items: center;
                  width: 2.5rem;
                  margin-right: 1.25rem;

                  .dot {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: $indicator-color;
                  }
                }
              }
            }

            .bottom {
              display: flex;
              flex-direction: column;
              .subtext {
                flex: 1;
                font-size: $subtext-size;
                color: $subtext-color;
              }

              .age {
                flex: 1;
                font-size: $subtext-size;
                color: $subtext-color;
              }
            }
          }
        }
      }
    }
  }
}
