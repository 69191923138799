// Sidebar variables
$sidebar-width: 225px;
$sidebar-collapsed-width: 52px;
$sidebar-icon-size: 1.25rem;
$sidebar-icon-color-inactive: rgb(0, 51, 65) !important;
$sidebar-icon-color-active: #fff !important;

// Sidebar colors
$scrollbarBg: rgba(0, 0, 0, 0.1);
$scollbarThumb: rgba(0, 0, 0, 0.2);
$sidebarbg: #e4e7eb;
$sidebar-inactive-text: rgb(0, 51, 65);
$sidebar-hover: rgb(51, 92, 103);
$sidebar-hover-text: #fff;
$sidebar-active: #fff;
$sidebar-active-text: rgb(0, 67, 136);

.custom-sidebar {
  height: calc(100vh - 56px);
  overflow: hidden;

  .app-version-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    //border-top: 1px solid rgba(173, 173, 173, 0.2);

    .app-version {
      position: absolute;
      left: 10px;
      font-size: small;
      padding: 0.5rem;

      // Fade animation
      visibility: visible;
      opacity: 1;
      transition: opacity 0.25s linear;
    }

    .sidebar-icon {
      transform: rotate(0deg);
      transition: transform 0.25s ease-in-out;
    }

    .menu-toggler {
      list-style-type: none;
      cursor: pointer;

      .pro-inner-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        .pro-item-content {
          display: none;
        }
      }
    }
  }

  .sidebar-icon {
    width: $sidebar-icon-size;
    height: $sidebar-icon-size;

    fill: $sidebar-icon-color-inactive;
  }

  .pro-sidebar {
    overflow: hidden;
    width: $sidebar-width;
    min-width: $sidebar-width;
    z-index: unset;

    &.collapsed {
      width: $sidebar-collapsed-width;
      min-width: $sidebar-collapsed-width;

      .app-version-wrapper {
        .app-version {
          // Fade out animation
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s 0.2s, opacity 0.2s linear,
            width 0.2s linear, padding 0.2s linear;
        }

        .sidebar-icon {
          transition: transform 0.25s ease-in-out;
          transform: rotate(180deg);
        }
      }

      .langauge-selection{
        .langauge-selection-text{
          // Fade out animation
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s 0.2s, opacity 0.2s linear,
            width 0.2s linear, padding 0.2s linear;
        }
      }
    }
  }

  .pro-sidebar-inner {
    background-color: $sidebarbg !important;
    width: 100%;
  }

  .pro-menu-item {
    .pro-item-content {
      color: $sidebar-inactive-text;
    }

    &.active,
    &:hover {
      .pro-inner-item {
        background-color: $sidebar-hover;

        border-radius: 5px;
      }

      .pro-item-content {
        color: $sidebar-hover-text;
      }

      .sidebar-icon {
        fill: $sidebar-active !important;
      }
    }

    .pro-inner-item {
      padding: 0 !important;
      margin: 12px 8px 12px 8px !important;
    }
  }

  .pro-icon-wrapper {
    .pro-icon {
      // animation: swing ease-in-out 0.5s 1 alternate;
      animation: none !important;
    }
  }

  .pro-sidebar-content {
    width: 100%;
  }

  .pro-sidebar-footer {
    width: 100%;
    border-top: none !important;

    .pro-menu {
      padding-bottom: 0 !important;
    }
  }

  .pro-sidebar-layout {
    width: 100%;
    /* height */
    &::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $scrollbarBg;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $scollbarThumb;
    }
  }

  .langauge-selection{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    border-top: 1px solid rgba(173, 173, 173, 0.2);
    .langauge-selection-text{
      position: absolute;
      left: 10px;
      font-size: small;
      padding: 0.5rem;

      // Fade animation
      visibility: visible;
      opacity: 1;
      transition: opacity 0.25s linear;
    }
  }
}
