$border-color: rgba(0, 0, 0, 0.125);
$option-height: 50px;

.sb_control-items {
  .sb_header {
    display: flex;
    flex-direction: column;
    flex: 1;

    .createBtn {
      width: fit-content;
      align-self: flex-end;
      margin-bottom: 0.5rem;
    }
  }

  .control-item-table {
    margin-bottom: 0;

    thead {
      tr {
        th:first-child {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: middle;

          .form-check {
            .form-check-label {
              .col {
                p {
                  margin-bottom: 0;
                }
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.sb_create-control-item {
  &.no-top {
    border-top: none !important;
  }

  .card-footer {
    .footer-btns {
      justify-content: flex-end;
    }
  }
}

.sb_setting_checkbox {
  margin-bottom: 0;

  label {
    margin-bottom: 0 !important;
  }

  .col-input {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;

    .form-check-input {
      margin-left: 0;
    }

    input {
      width: 20px;
      height: 20px;
      margin-top: 0;
    }
  }
}

.sb_edit-control-item {
  border-top: none !important;

  &_header {
    margin-bottom: 1rem;
  }

  .panels {
    padding: 0;
    display: flex;
    flex-direction: row;
  }

  .panel {
    flex: 1;
    padding-bottom: 1rem;

    p,
    h2 {
      margin-bottom: 0 !important;
    }
    .title {
      text-transform: uppercase;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 3rem;
      border-bottom: 1px solid $border-color;
    }

    &.left {
      border-right: 1px solid $border-color;

      .custom-datepicker {
        align-items: center;
        margin-left: 0;
        justify-content: flex-start;
        .react-datepicker-wrapper {
          flex: 0;
        }
        .date-clear {
          position: relative;
          margin-left: -1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }

    .content {
      margin: 1.75rem;
    }

    .spacer {
      margin: 1.75rem;
    }

    .options {
      display: flex;
      gap: 0.5rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .option {
        height: $option-height;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .arrows {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 30px;
          max-width: 30px;

          .fakeDown,
          .fakeUp {
            width: 20px;
            height: 20px;
          }
        }

        .form-group {
          margin-bottom: 0;
          width: 100%;

          .form-control {
            border-right-width: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .removeBtn {
          height: 38px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .add-option {
        height: $option-height;
        width: 100%;
        display: flex;
        border: 1px dashed darkgray;
        color: darkgray;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .card-footer {
    .footer-btns {
      justify-content: flex-end;
    }
  }
}

.sb_used-in-table {
  .card {
    //border: none !important;
  }
  .custom-table-wrapper {
    height: 45vh !important;
    max-height: 45vh !important;
  }
}

.control-item-text-presentation-area {
  background-color: #e9ecef;
  opacity: 1;
  height: auto;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  p {
    min-height: 21px;
  }
}

.centered-watermark-text {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  border: 1px dashed $border-color;

  height: 60px;
  &__text {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.125);
  }
}

.timetable-preview {
  margin-top: 1rem;

  .timetable-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 0;
      height: 40px;
    }

    .name {
      flex: 1;
    }

    .hours {
      input {
        width: 60px;
        text-align: center;
        padding-right: 0;
      }
    }
  }

  .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.control-item-timetable-users {
  .item {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;

    .name {
      flex: 1;
    }
    input {
      max-width: 5rem;
    }
  }
  .total {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    font-weight: bold;

    .title {
      flex: 1;
    }

    .sum {
      max-width: 5rem;
    }
  }
}

.sb-signature-preview {
  .centered-watermark-text {
    height: 100px;
    border: 1px dashed $border-color;
  }

  .img-preview {
    max-width: 100%;
    border: 1px dashed $border-color;
  }

  &.sigmodal {
    width: 100%;
    .img-preview {
      width: 100%;
      border: 1px solid $border-color;
    }
  }
}
