$empty: rgba(0, 0, 0, 0.03);
$empty-text: rgba(0, 0, 0, 0.5);
$hoverBg: rgba(0, 0, 0, 0.125);

.custom-table-wrapper {
  .empty-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    // background-color: $empty;
    pointer-events: none;

    .text {
      flex: 1;
      text-align: center;
      color: $empty-text;
    }
  }

  .custom-table {
    margin-bottom: 0;

    .header {
      th {
        vertical-align: bottom;
        padding: 0.75rem;
      }
    }
    .body {
      tr {
        &.clickable-row {
          cursor: pointer;
          &:hover {
            background-color: $hoverBg;
          }
        }

        &.hover {
          &:hover {
            background-color: $hoverBg;
          }
        }
      }

      td {
        vertical-align: middle;
        padding: 0.75rem;

        .form-group {
          margin-bottom: 0;
        }
      }

      .category-check {
        label {
          display: flex;
          align-items: center;

          input {
            margin-top: 0;
          }

          .col {
            display: flex;
            align-items: center;
            p {
              flex: 1;
              padding-top: 0 !important;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
