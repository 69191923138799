h1 {
  font-size: 32px !important;
}

h2 {
  font-size: 28px !important;
}

h3 {
  font-size: 24px !important;
}

h4 {
  font-size: 24px !important;
}

h5 {
  font-size: 20px !important;
}

th {
  font-size: 14px !important;
}

label {
  font-size: 14px !important;
}

body,
a,
td,
p {
  font-size: 14px !important;
}

// span {
//   font-size: 12px !important;
// }

.badge {
  font-size: 12px;
}

.btn {
  font-size: unset;
}

.form-control {
  font-size: unset;
  height: calc(1.5em + 0.75rem + 5px);
}

//
// Exceptions
//
.filter__status_card_count {
  font-size: 24px !important;
}

.pro-item-content {
  font-size: 14px !important;
}

.modal-header {
  .functions {
    font-size: 14px;
  }
  .save-menu {
    font-size: 14px;
  }
  .close {
    span {
      font-size: unset !important;
    }
  }
}

.stats-font {
  font-size: 14px !important;
}
.filter__status_card_text {
  font-size: 12px !important;
}
.feature__status_card_text {
  font-size: 12px !important;
}
.documents-options {
  span {
    font-size: 16px !important;
  }
}
.custom-button-dropdown {
  span {
    font-size: 16px !important;
  }
}
.roadmap__content__items__material .material__card .content .product {
  font-size: 14px !important;
}
.sortmenu {
  span {
    font-size: 14px !important;
  }
}
.roadmap__list__item.materials .title .date-info {
  font-size: 12px !important;
}
.dropdown-menu {
  font-size: 14px;
}
.tp-item-number {
  span {
    font-size: 14px !important;
  }
}
