.Toastify__toast--info {
  background: theme-color('info');
}
.Toastify__toast--success {
  background: theme-color('success');
}
.Toastify__toast--warning {
  background: theme-color('warning');
}
.Toastify__toast--error {
  background: theme-color('danger');
}
.Toastify__progress-bar--animated{
  right: 0;
  left: auto;
  left: initial;
  -webkit-transform-origin: right;
  transform-origin: right;
}
