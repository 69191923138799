.custom-modal {
  &.xl-size {
    max-width: 75vw !important;
  }

  &.fullscreen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    max-width: 100vw;

    .modal-content {
      height: 100vh;
      width: 100vw;
    }
  }

  &.moving-images {
    max-width: 85vw !important;

    .modal-body {
      min-height: 75vh !important;
      max-height: 75vh !important;
      overflow: auto;
    }
  }

  &.overlay {
    background: transparent;

    .modal-content {
      background: rgba(255, 255, 255, 0.5);
      border: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .internal-user-data {
    // height: 75vh;
    // max-height: 80vh;
    // overflow: auto;
  }

  .custom-table-wrapper {
    height: 75vh;
    max-height: 75vh;
    overflow: auto;

    .product-table {
      .category-check {
        label {
          display: flex;
          justify-content: center;
          .col {
            display: none;
          }
        }
      }
    }
  }
}
