.language_manager {
  .language_filters {
    display: flex;
    margin-bottom: 1rem;
    flex: 1;
    gap: 1rem;

    .filters {
      display: flex;
      gap: 1rem;
      flex: 1;
    }

    .btns {
      display: flex;
      align-items: flex-end;
      gap: 1rem;

      button {
        height: 100%;
      }

      .downloadBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner {
          margin-left: 0.5rem;
        }
      }
    }

    .custom-react-select {
      flex: 1;
      max-width: 400px;
    }
  }

  .approvals {
    display: flex;
    gap: 0.5rem;
    max-width: 100%;
    flex-wrap: wrap;

    .lang {
      width: 1.75rem;
      height: 1.75rem;
      min-width: 1.75rem;
      max-width: 1.75rem;

      cursor: default;
      flex: 1;

      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 50px;

      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 70%;
        word-wrap: initial;
        height: 100%;
        text-transform: uppercase;
      }
    }
  }
}

.language-modal-item {
  max-height: 75vh;
  overflow: auto;

  .language-keys {
    .lang-input {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .input {
        flex: 1;

        &.missing {
          textarea {
            background-color: #fffcbc;
          }
        }
      }

      .approval {
        width: 150px;
      }
    }
  }
}
