$success: #45b649;
$danger: #f85032;
.add-schema-to-package-modal {
  .custom-table-wrapper {
    height: 70vh !important;
  }
}
.card-footer {
  .footer-btns {
    justify-content: flex-end;
  }
}
.schema-package-comparison {
  &__header {
    margin-right: 0px;
    margin-left: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .first {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  &__body {
    margin-right: 0px;
    margin-left: 0px;
    .first {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  &__list {
    padding-top: 10px;
    .added {
      color: $success;
    }
    .removed {
      color: $danger;
    }
  }
}
