/* --------------------------------------------- */
/* Used to achieve actual wysiwyg in WEB and PDF */
/* --------------------------------------------- */

/************************************************************************************/
/************ DO NOT CHANGE WITHOUT ALSO CHANGING API WYSIWYG STYLESHEET ************/
/************************************************************************************/

/* Reset existing */
.wysiwyg-section-default {
  all: initial !important;
  font-family: 'Roboto', sans-serif !important;
}

.wysiwyg-section-default html {
  display: block;
}

.wysiwyg-section-default head {
  display: none;
}

.wysiwyg-section-default meta {
  display: none;
}

.wysiwyg-section-default title {
  display: none;
}

.wysiwyg-section-default link {
  display: none;
}

.wysiwyg-section-default style {
  display: none;
}

.wysiwyg-section-default script {
  display: none;
}

.wysiwyg-section-default body {
  display: block;
  margin: 8px;
}

.wysiwyg-section-default body:-webkit-full-page-media {
  background-color: #000;
}

.wysiwyg-section-default p {
  display: block;
  -webkit-margin-before: 1 __qem;
  -webkit-margin-after: 1 __qem;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

.wysiwyg-section-default div:not(.rdw-image-alignment-options-popup) {
  display: block;
}

.wysiwyg-section-default layer {
  display: block;
}

.wysiwyg-section-default article,
.wysiwyg-section-default aside,
.wysiwyg-section-default footer,
.wysiwyg-section-default header,
.wysiwyg-section-default hgroup,
.wysiwyg-section-default main,
.wysiwyg-section-default nav,
.wysiwyg-section-default section {
  display: block;
}

.wysiwyg-section-default marquee {
  display: inline-block;
}

.wysiwyg-section-default address {
  display: block;
}

.wysiwyg-section-default blockquote {
  display: block;
  -webkit-margin-before: 1 __qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px;
}

.wysiwyg-section-default figcaption {
  display: block;
}

.wysiwyg-section-default figure {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px;
}

.wysiwyg-section-default q {
  display: inline;
}

.wysiwyg-section-default q:before {
  content: open-quote;
}

.wysiwyg-section-default q:after {
  content: close-quote;
}

.wysiwyg-section-default center {
  display: block;
  /* special centering to be able to emulate the html4/netscape behaviour */
  text-align: -webkit-center;
}

.wysiwyg-section-default hr {
  display: block;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  border-style: inset;
  border-width: 1px;
}

.wysiwyg-section-default map {
  display: inline;
}

.wysiwyg-section-default video {
  object-fit: contain;
}

.wysiwyg-section-default h1 {
  display: block;
  font-size: 2em;
  -webkit-margin-before: 0.67 __qem;
  -webkit-margin-after: 0.67em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

.wysiwyg-section-default :-webkit-any(article, aside, nav, section)h1 {
  font-size: 1.5em;
  -webkit-margin-before: 0.83 __qem;
  -webkit-margin-after: 0.83em;
}

.wysiwyg-section-default
  :-webkit-any(article, aside, nav, section):-webkit-any(article, aside, nav, section)h1 {
  font-size: 1.17em;
  -webkit-margin-before: 1 __qem;
  -webkit-margin-after: 1em;
}

.wysiwyg-section-default
  :-webkit-any(article, aside, nav, section):-webkit-any(article, aside, nav, section):-webkit-any(article, aside, nav, section)h1 {
  font-size: 1em;
  -webkit-margin-before: 1.33 __qem;
  -webkit-margin-after: 1.33em;
}

.wysiwyg-section-default
  :-webkit-any(article, aside, nav, section):-webkit-any(article, aside, nav, section):-webkit-any(article, aside, nav, section):-webkit-any(article, aside, nav, section)h1 {
  font-size: 0.83em;
  -webkit-margin-before: 1.67 __qem;
  -webkit-margin-after: 1.67em;
}

.wysiwyg-section-default
  :-webkit-any(article, aside, nav, section):-webkit-any(article, aside, nav, section):-webkit-any(article, aside, nav, section):-webkit-any(article, aside, nav, section):-webkit-any(article, aside, nav, section)h1 {
  font-size: 0.67em;
  -webkit-margin-before: 2.33 __qem;
  -webkit-margin-after: 2.33em;
}

.wysiwyg-section-default h2 {
  display: block;
  font-size: 1.5em;
  -webkit-margin-before: 0.83 __qem;
  -webkit-margin-after: 0.83em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

.wysiwyg-section-default h3 {
  display: block;
  font-size: 1.17em;
  -webkit-margin-before: 1 __qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

.wysiwyg-section-default h4 {
  display: block;
  -webkit-margin-before: 1.33 __qem;
  -webkit-margin-after: 1.33em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

.wysiwyg-section-default h5 {
  display: block;
  font-size: 0.83em;
  -webkit-margin-before: 1.67 __qem;
  -webkit-margin-after: 1.67em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

.wysiwyg-section-default h6 {
  display: block;
  font-size: 0.67em;
  -webkit-margin-before: 2.33 __qem;
  -webkit-margin-after: 2.33em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

.wysiwyg-section-default table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
}

.wysiwyg-section-default thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.wysiwyg-section-default tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.wysiwyg-section-default tfoot {
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
}

.wysiwyg-section-default table > tr {
  vertical-align: middle;
}

.wysiwyg-section-default col {
  display: table-column;
}

.wysiwyg-section-default colgroup {
  display: table-column-group;
}

.wysiwyg-section-default tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.wysiwyg-section-default td,
.wysiwyg-section-default th {
  display: table-cell;
  vertical-align: inherit;
}

.wysiwyg-section-default th {
  font-weight: bold;
}

.wysiwyg-section-default caption {
  display: table-caption;
  text-align: -webkit-center;
}

.wysiwyg-section-default ul,
.wysiwyg-section-default menu,
.wysiwyg-section-default dir {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1 __qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px;
}

.wysiwyg-section-default ol {
  display: block;
  list-style-type: decimal;
  -webkit-margin-before: 1 __qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px;
}

.wysiwyg-section-default li {
  display: list-item;
  text-align: -webkit-match-parent;
  font-size: 16px;
}

.wysiwyg-section-default ul ul,
.wysiwyg-section-default ol ul {
  list-style-type: circle;
}

.wysiwyg-section-default ol ol ul,
.wysiwyg-section-default ol ul ul,
.wysiwyg-section-default ul ol ul,
.wysiwyg-section-default ul ul ul {
  list-style-type: square;
}

.wysiwyg-section-default dd {
  display: block;
  -webkit-margin-start: 40px;
}

.wysiwyg-section-default dl {
  display: block;
  -webkit-margin-before: 1 __qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

.wysiwyg-section-default dt {
  display: block;
}

.wysiwyg-section-default ol ul,
.wysiwyg-section-default ul ol,
.wysiwyg-section-default ul ul,
.wysiwyg-section-default ol ol {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

.wysiwyg-section-default form {
  display: block;
  margin-top: 0 __qem;
}

.wysiwyg-section-default label {
  cursor: default;
}

.wysiwyg-section-default legend {
  display: block;
  -webkit-padding-start: 2px;
  -webkit-padding-end: 2px;
  border: none;
}

.wysiwyg-section-default fieldset {
  display: block;
  -webkit-margin-start: 2px;
  -webkit-margin-end: 2px;
  -webkit-padding-before: 0.35em;
  -webkit-padding-start: 0.75em;
  -webkit-padding-end: 0.75em;
  -webkit-padding-after: 0.625em;
  border: 2px groove ThreeDFace;
  min-width: -webkit-min-content;
}

.wysiwyg-section-default button {
  -webkit-appearance: button;
}

.wysiwyg-section-default input,
.wysiwyg-section-default textarea,
.wysiwyg-section-default keygen,
.wysiwyg-section-default select,
.wysiwyg-section-default button,
.wysiwyg-section-default meter,
.wysiwyg-section-default progress {
  -webkit-writing-mode: horizontal-tb !important;
}

.wysiwyg-section-default input,
.wysiwyg-section-default textarea,
.wysiwyg-section-default keygen,
.wysiwyg-section-default select,
.wysiwyg-section-default button {
  margin: 0 __qem;
  font: -webkit-small-control;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  text-align: start;
}

.wysiwyg-section-default input[type='hidden' i] {
  display: none;
}

.wysiwyg-section-default input,
.wysiwyg-section-default input[type='password' i],
.wysiwyg-section-default input[type='search' i] {
  -webkit-appearance: textfield;
  padding: 1px;
  background-color: white;
  border: 2px inset;
  -webkit-rtl-ordering: logical;
  -webkit-user-select: text;
  cursor: auto;
}

.wysiwyg-section-default input[type='search' i] {
  -webkit-appearance: searchfield;
  box-sizing: border-box;
}

.wysiwyg-section-default input::-webkit-textfield-decoration-container {
  display: flex;
  align-items: center;
  -webkit-user-modify: read-only !important;
  content: none !important;
}

.wysiwyg-section-default
  input[type='search' i]::-webkit-textfield-decoration-container {
  direction: ltr;
}

.wysiwyg-section-default input::-webkit-clear-button {
  -webkit-appearance: searchfield-cancel-button;
  display: inline-block;
  flex: none;
  -webkit-user-modify: read-only !important;
  -webkit-margin-start: 2px;
  opacity: 0;
  pointer-events: none;
}

.wysiwyg-section-default
  input:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-clear-button {
  opacity: 1;
  pointer-events: auto;
}

.wysiwyg-section-default input[type='search' i]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  display: block;
  flex: none;
  -webkit-user-modify: read-only !important;
  -webkit-margin-start: 1px;
  opacity: 0;
  pointer-events: none;
}

.wysiwyg-section-default
  input[type='search' i]:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-search-cancel-button {
  opacity: 1;
  pointer-events: auto;
}

.wysiwyg-section-default input[type='search' i]::-webkit-search-decoration {
  -webkit-appearance: searchfield-decoration;
  display: block;
  flex: none;
  -webkit-user-modify: read-only !important;
  -webkit-align-self: flex-start;
  margin: auto 0;
}

.wysiwyg-section-default
  input[type='search' i]::-webkit-search-results-decoration {
  -webkit-appearance: searchfield-results-decoration;
  display: block;
  flex: none;
  -webkit-user-modify: read-only !important;
  -webkit-align-self: flex-start;
  margin: auto 0;
}

.wysiwyg-section-default input::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  display: inline-block;
  cursor: default;
  flex: none;
  align-self: stretch;
  -webkit-user-select: none;
  -webkit-user-modify: read-only !important;
  opacity: 0;
  pointer-events: none;
}

.wysiwyg-section-default
  input:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-inner-spin-button {
  opacity: 1;
  pointer-events: auto;
}

.wysiwyg-section-default keygen,
.wysiwyg-section-default select {
  border-radius: 5px;
}

.wysiwyg-section-default keygen::-webkit-keygen-select {
  margin: 0px;
}

.wysiwyg-section-default textarea {
  -webkit-appearance: textarea;
  background-color: white;
  border: 1px solid;
  -webkit-rtl-ordering: logical;
  -webkit-user-select: text;
  flex-direction: column;
  resize: auto;
  cursor: auto;
  padding: 2px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.wysiwyg-section-default ::-webkit-input-placeholder {
  -webkit-text-security: none;
  color: darkGray;
  display: block !important;
  pointer-events: none !important;
}

.wysiwyg-section-default input::-webkit-input-placeholder {
  white-space: pre;
  word-wrap: normal;
  overflow: hidden;
  -webkit-user-modify: read-only !important;
}

.wysiwyg-section-default input[type='password' i] {
  -webkit-text-security: disc !important;
}

.wysiwyg-section-default input[type='hidden' i],
.wysiwyg-section-default input[type='image' i],
.wysiwyg-section-default input[type='file' i] {
  -webkit-appearance: initial;
  padding: initial;
  background-color: initial;
  border: initial;
}

.wysiwyg-section-default input[type='file' i] {
  align-items: baseline;
  color: inherit;
  text-align: start !important;
}

.wysiwyg-section-default input:-webkit-autofill,
.wysiwyg-section-default textarea:-webkit-autofill,
.wysiwyg-section-default select:-webkit-autofill {
  background-color: #faffbd !important;
  background-image: none !important;
  color: #000 !important;
}

.wysiwyg-section-default input[type='radio' i],
.wysiwyg-section-default input[type='checkbox' i] {
  margin: 3px 0.5ex;
  padding: initial;
  background-color: initial;
  border: initial;
}

.wysiwyg-section-default input[type='button' i],
.wysiwyg-section-default input[type='submit' i],
.wysiwyg-section-default input[type='reset' i] {
  -webkit-appearance: push-button;
  -webkit-user-select: none;
  white-space: pre;
}

.wysiwyg-section-default input[type='file' i]::-webkit-file-upload-button {
  -webkit-appearance: push-button;
  -webkit-user-modify: read-only !important;
  white-space: nowrap;
  margin: 0;
  font-size: inherit;
}

.wysiwyg-section-default input[type='button' i],
.wysiwyg-section-default input[type='submit' i],
.wysiwyg-section-default input[type='reset' i],
.wysiwyg-section-default input[type='file' i]::-webkit-file-upload-button,
.wysiwyg-section-default button {
  align-items: flex-start;
  text-align: center;
  cursor: default;
  color: ButtonText;
  padding: 2px 6px 3px 6px;
  border: 2px outset ButtonFace;
  background-color: ButtonFace;
  box-sizing: border-box;
}

.wysiwyg-section-default input[type='range' i] {
  -webkit-appearance: slider-horizontal;
  padding: initial;
  border: initial;
  margin: 2px;
  color: #909090;
}

.wysiwyg-section-default input[type='range' i]::-webkit-slider-container,
.wysiwyg-section-default input[type='range' i]::-webkit-media-slider-container {
  flex: 1;
  box-sizing: border-box;
  -webkit-user-modify: read-only !important;
  display: flex;
  -webkit-align-contents: center;
}

.wysiwyg-section-default input[type='range' i]::-webkit-slider-runnable-track {
  flex: 1;
  -webkit-align-self: center;
  box-sizing: border-box;
  -webkit-user-modify: read-only !important;
  display: block;
}

.wysiwyg-section-default input[type='range' i]::-webkit-slider-thumb,
.wysiwyg-section-default input[type='range' i]::-webkit-media-slider-thumb {
  -webkit-appearance: sliderthumb-horizontal;
  box-sizing: border-box;
  -webkit-user-modify: read-only !important;
  display: block;
}

.wysiwyg-section-default input[type='button' i]:disabled,
.wysiwyg-section-default input[type='submit' i]:disabled,
.wysiwyg-section-default input[type='reset' i]:disabled,
.wysiwyg-section-default
  input[type='file' i]:disabled::-webkit-file-upload-button,
.wysiwyg-section-default button:disabled,
.wysiwyg-section-default select:disabled,
.wysiwyg-section-default keygen:disabled,
.wysiwyg-section-default optgroup:disabled,
.wysiwyg-section-default option:disabled,
.wysiwyg-section-default select[disabled] > option {
  color: GrayText;
}

.wysiwyg-section-default input[type='button' i]:active,
.wysiwyg-section-default input[type='submit' i]:active,
.wysiwyg-section-default input[type='reset' i]:active,
.wysiwyg-section-default
  input[type='file' i]:active::-webkit-file-upload-button,
.wysiwyg-section-default button:active {
  border-style: inset;
}

.wysiwyg-section-default input[type='button' i]:active:disabled,
.wysiwyg-section-default input[type='submit' i]:active:disabled,
.wysiwyg-section-default input[type='reset' i]:active:disabled,
.wysiwyg-section-default
  input[type='file' i]:active:disabled::-webkit-file-upload-button,
.wysiwyg-section-default button:active:disabled {
  border-style: outset;
}

.wysiwyg-section-default option:-internal-spatial-navigation-focus {
  outline: black dashed 1px;
  outline-offset: -1px;
}

.wysiwyg-section-default datalist {
  display: none;
}

.wysiwyg-section-default area {
  display: inline;
}

.wysiwyg-section-default param {
  display: none;
}

.wysiwyg-section-default input[type='checkbox' i] {
  -webkit-appearance: checkbox;
  box-sizing: border-box;
}

.wysiwyg-section-default input[type='radio' i] {
  -webkit-appearance: radio;
  box-sizing: border-box;
}

.wysiwyg-section-default input[type='color' i] {
  -webkit-appearance: square-button;
  width: 44px;
  height: 23px;
  background-color: ButtonFace;
  /* Same as native_theme_base. */
  border: 1px #a9a9a9 solid;
  padding: 1px 2px;
}

.wysiwyg-section-default input[type='color' i]::-webkit-color-swatch-wrapper {
  display: flex;
  padding: 4px 2px;
  box-sizing: border-box;
  -webkit-user-modify: read-only !important;
  width: 100%;
  height: 100%;
}

.wysiwyg-section-default input[type='color' i]::-webkit-color-swatch {
  background-color: #000;
  border: 1px solid #777;
  flex: 1;
  -webkit-user-modify: read-only !important;
}

.wysiwyg-section-default input[type='color' i][list] {
  -webkit-appearance: menulist;
  width: 88px;
  height: 23px;
}

.wysiwyg-section-default
  input[type='color' i][list]::-webkit-color-swatch-wrapper {
  padding-left: 8px;
  padding-right: 24px;
}

.wysiwyg-section-default input[type='color' i][list]::-webkit-color-swatch {
  border-color: #000;
}

.wysiwyg-section-default input::-webkit-calendar-picker-indicator {
  display: inline-block;
  width: 0.66em;
  height: 0.66em;
  padding: 0.17em 0.34em;
  -webkit-user-modify: read-only !important;
  opacity: 0;
  pointer-events: none;
}

.wysiwyg-section-default input::-webkit-calendar-picker-indicator:hover {
  background-color: #eee;
}

.wysiwyg-section-default
  input:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-calendar-picker-indicator,
.wysiwyg-section-default input::-webkit-calendar-picker-indicator:focus {
  opacity: 1;
  pointer-events: auto;
}

.wysiwyg-section-default input[type='date' i]:disabled::-webkit-clear-button,
.wysiwyg-section-default
  input[type='date' i]:disabled::-webkit-inner-spin-button,
.wysiwyg-section-default
  input[type='datetime-local' i]:disabled::-webkit-clear-button,
.wysiwyg-section-default
  input[type='datetime-local' i]:disabled::-webkit-inner-spin-button,
.wysiwyg-section-default input[type='month' i]:disabled::-webkit-clear-button,
.wysiwyg-section-default
  input[type='month' i]:disabled::-webkit-inner-spin-button,
.wysiwyg-section-default input[type='week' i]:disabled::-webkit-clear-button,
.wysiwyg-section-default
  input[type='week' i]:disabled::-webkit-inner-spin-button,
.wysiwyg-section-default input:disabled::-webkit-calendar-picker-indicator,
.wysiwyg-section-default input[type='date' i][readonly]::-webkit-clear-button,
.wysiwyg-section-default
  input[type='date' i][readonly]::-webkit-inner-spin-button,
.wysiwyg-section-default
  input[type='datetime-local' i][readonly]::-webkit-clear-button,
.wysiwyg-section-default
  input[type='datetime-local' i][readonly]::-webkit-inner-spin-button,
.wysiwyg-section-default input[type='month' i][readonly]::-webkit-clear-button,
.wysiwyg-section-default
  input[type='month' i][readonly]::-webkit-inner-spin-button,
.wysiwyg-section-default input[type='week' i][readonly]::-webkit-clear-button,
.wysiwyg-section-default
  input[type='week' i][readonly]::-webkit-inner-spin-button,
.wysiwyg-section-default input[readonly]::-webkit-calendar-picker-indicator {
  visibility: hidden;
}

.wysiwyg-section-default select {
  -webkit-appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  color: black;
  background-color: white;
  cursor: default;
}

.wysiwyg-section-default select:-internal-list-box {
  -webkit-appearance: listbox;
  align-items: flex-start;
  border: 1px inset gray;
  border-radius: initial;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: text-bottom;
  -webkit-user-select: none;
  white-space: nowrap;
}

.wysiwyg-section-default optgroup {
  font-weight: bolder;
  display: block;
}

.wysiwyg-section-default option {
  font-weight: normal;
  display: block;
  padding: 0 2px 1px 2px;
  white-space: pre;
  min-height: 1.2em;
}

.wysiwyg-section-default select:-internal-list-box option,
.wysiwyg-section-default select:-internal-list-box optgroup {
  line-height: initial !important;
}

.wysiwyg-section-default select:-internal-list-box:focus option:checked {
  background-color: -internal-active-list-box-selection !important;
  color: -internal-active-list-box-selection-text !important;
}

.wysiwyg-section-default select:-internal-list-box option:checked {
  background-color: -internal-inactive-list-box-selection !important;
  color: -internal-inactive-list-box-selection-text !important;
}

.wysiwyg-section-default select:-internal-list-box:disabled option:checked,
.wysiwyg-section-default select:-internal-list-box option:checked:disabled {
  color: gray !important;
}

.wysiwyg-section-default output {
  display: inline;
}

.wysiwyg-section-default meter {
  -webkit-appearance: meter;
  box-sizing: border-box;
  display: inline-block;
  height: 1em;
  width: 5em;
  vertical-align: -0.2em;
}

.wysiwyg-section-default meter::-webkit-meter-inner-element {
  -webkit-appearance: inherit;
  box-sizing: inherit;
  -webkit-user-modify: read-only !important;
  height: 100%;
  width: 100%;
}

.wysiwyg-section-default meter::-webkit-meter-bar {
  background: linear-gradient(
    to bottom,
    #ddd,
    #eee 20%,
    #ccc 45%,
    #ccc 55%,
    #ddd
  );
  height: 100%;
  width: 100%;
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}

.wysiwyg-section-default meter::-webkit-meter-optimum-value {
  background: linear-gradient(
    to bottom,
    #ad7,
    #cea 20%,
    #7a3 45%,
    #7a3 55%,
    #ad7
  );
  height: 100%;
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}

.wysiwyg-section-default meter::-webkit-meter-suboptimum-value {
  background: linear-gradient(
    to bottom,
    #fe7,
    #ffc 20%,
    #db3 45%,
    #db3 55%,
    #fe7
  );
  height: 100%;
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}

.wysiwyg-section-default meter::-webkit-meter-even-less-good-value {
  background: linear-gradient(
    to bottom,
    #f77,
    #fcc 20%,
    #d44 45%,
    #d44 55%,
    #f77
  );
  height: 100%;
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}

.wysiwyg-section-default progress {
  -webkit-appearance: progress-bar;
  box-sizing: border-box;
  display: inline-block;
  height: 1em;
  width: 10em;
  vertical-align: -0.2em;
}

.wysiwyg-section-default progress::-webkit-progress-inner-element {
  -webkit-appearance: inherit;
  box-sizing: inherit;
  -webkit-user-modify: read-only;
  height: 100%;
  width: 100%;
}

.wysiwyg-section-default progress::-webkit-progress-bar {
  background-color: gray;
  height: 100%;
  width: 100%;
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}

.wysiwyg-section-default progress::-webkit-progress-value {
  background-color: green;
  height: 100%;
  width: 50%;
  /* should be removed later */
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}

.wysiwyg-section-default u,
.wysiwyg-section-default ins {
  text-decoration: underline;
}

.wysiwyg-section-default strong,
.wysiwyg-section-default b {
  font-weight: bold;
}

.wysiwyg-section-default i,
.wysiwyg-section-default cite,
.wysiwyg-section-default em,
.wysiwyg-section-default var,
.wysiwyg-section-default address,
.wysiwyg-section-default dfn {
  font-style: italic;
}

.wysiwyg-section-default tt,
.wysiwyg-section-default code,
.wysiwyg-section-default kbd,
.wysiwyg-section-default samp {
  font-family: monospace;
}

.wysiwyg-section-default pre,
.wysiwyg-section-default xmp,
.wysiwyg-section-default plaintext,
.wysiwyg-section-default listing {
  display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1 __qem 0;
}

.wysiwyg-section-default mark {
  background-color: yellow;
  color: black;
}

.wysiwyg-section-default big {
  font-size: larger;
}

.wysiwyg-section-default small {
  font-size: smaller;
}

.wysiwyg-section-default s,
.wysiwyg-section-default strike,
.wysiwyg-section-default del {
  text-decoration: line-through;
}

.wysiwyg-section-default sub {
  vertical-align: sub;
  font-size: smaller;
}

.wysiwyg-section-default sup {
  vertical-align: super;
  font-size: smaller;
}

.wysiwyg-section-default nobr {
  white-space: nowrap;
}

.wysiwyg-section-default :focus {
  outline: auto 5px -webkit-focus-ring-color;
}

.wysiwyg-section-default html:focus,
.wysiwyg-section-default body:focus,
.wysiwyg-section-default input[readonly]:focus {
  outline: none;
}

.wysiwyg-section-default applet:focus,
.wysiwyg-section-default embed:focus,
.wysiwyg-section-default iframe:focus,
.wysiwyg-section-default object:focus {
  outline: none;
}

.wysiwyg-section-default input:focus,
.wysiwyg-section-default textarea:focus,
.wysiwyg-section-default keygen:focus,
.wysiwyg-section-default select:focus {
  outline-offset: -2px;
}

.wysiwyg-section-default input[type='button' i]:focus,
.wysiwyg-section-default input[type='checkbox' i]:focus,
.wysiwyg-section-default input[type='file' i]:focus,
.wysiwyg-section-default input[type='hidden' i]:focus,
.wysiwyg-section-default input[type='image' i]:focus,
.wysiwyg-section-default input[type='radio' i]:focus,
.wysiwyg-section-default input[type='reset' i]:focus,
.wysiwyg-section-default input[type='search' i]:focus,
.wysiwyg-section-default input[type='submit' i]:focus,
.wysiwyg-section-default
  input[type='file' i]:focus::-webkit-file-upload-button {
  outline-offset: 0;
}

.wysiwyg-section-default a:-webkit-any-link {
  color: -webkit-link;
  text-decoration: underline;
  cursor: auto;
}

.wysiwyg-section-default a:-webkit-any-link:active {
  color: -webkit-activelink;
}

.wysiwyg-section-default ruby,
.wysiwyg-section-default rt {
  text-indent: 0;
  /* blocks used for ruby rendering should not trigger this */
}

.wysiwyg-section-default rt {
  line-height: normal;
  -webkit-text-emphasis: none;
}

.wysiwyg-section-default ruby > rt {
  display: block;
  font-size: 50%;
  text-align: start;
}

.wysiwyg-section-default ruby > rp {
  display: none;
}

.wysiwyg-section-default noframes {
  display: none;
}

.wysiwyg-section-default frameset,
.wysiwyg-section-default frame {
  display: block;
}

.wysiwyg-section-default frameset {
  border-color: inherit;
}

.wysiwyg-section-default iframe {
  border: 2px inset;
}

.wysiwyg-section-default details {
  display: block;
}

.wysiwyg-section-default summary {
  display: block;
}

.wysiwyg-section-default summary::-webkit-details-marker {
  display: inline-block;
  width: 0.66em;
  height: 0.66em;
  -webkit-margin-end: 0.4em;
}

.wysiwyg-section-default template {
  display: none;
}

.wysiwyg-section-default bdi,
.wysiwyg-section-default output {
  unicode-bidi: -webkit-isolate;
}

.wysiwyg-section-default bdo {
  unicode-bidi: bidi-override;
}

.wysiwyg-section-default textarea[dir='auto' i] {
  unicode-bidi: -webkit-plaintext;
}

.wysiwyg-section-default dialog:not([open]) {
  display: none;
}

.wysiwyg-section-default dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -webkit-fit-content;
  height: -webkit-fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
}

.wysiwyg-section-default dialog::backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}
